import Reflux from 'reflux'
import _ from 'underscore'
import socket from '../../socketio/socketio.js'
import filter from '../utils/filter.js'

import UserActions from '../actions/UserActions.js'

export default class UserStore extends Reflux.Store {
    constructor() {
        super()

        this.state = {
            unfilteredUsers: [],
            users: [],
            selectedUsers: [],
            usersLoading: false,
            usersSortKey: 'name',
            usersSortReverse: false,
            userQueries: []
        }

        this.listenables = UserActions

        UserActions.get(true)

        socket.on('updateUsers', () => {
            UserActions.get()
        })
    }

    onChangeQueries(userQueries) {
        const {unfilteredUsers} = this.state

        const users = filter.users(unfilteredUsers, userQueries)

        this.setState({users, userQueries})
    }

    onChangeSortKey(newSortKey) {
        let {users, usersSortKey, usersSortReverse} = this.state

        if (usersSortKey === newSortKey) {
            usersSortReverse = !usersSortReverse
        } else {
            usersSortReverse = false
        }

        usersSortKey = newSortKey

        users = _.sortBy(users, usersSortKey)

        if (usersSortReverse) {
            users.reverse()
        }

        this.setState({users, usersSortKey, usersSortReverse})
    }

    onSetSelected(selectedUsers) {
        this.setState({selectedUsers})
    }

    onGet(loading) {
        this.setState({usersLoading: !!loading})

        socket.emit('users.get', sessionStorage.token, (err, unfilteredUsers) => {
            if (!err) {
                const {userQueries, usersSortKey, usersSortReverse} = this.state

                let users = filter.users(unfilteredUsers, userQueries)

                users = _.sortBy(users, usersSortKey)
                if (usersSortReverse) {
                    users.reverse()
                }

                this.setState({unfilteredUsers, users, usersLoading: false})
            }
        })
    }

    onUpdate(user, callback) {
        socket.emit('users.update', sessionStorage.token, user, callback)
    }

    onRemove(id, callback) {
        socket.emit('users.remove', sessionStorage.token, id, callback)
    }

    onSendWelcomeMail(email, callback) {
        socket.emit('user.sendWelcomeMail', sessionStorage.token, email, callback)
    }
}
