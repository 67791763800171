import React from 'react'
import Radium from 'radium'

import {Form} from 'react-bootstrap'
import {IconButton, Colors} from '../UI'


class DeliveryTable extends React.Component {
    onClickMinDelivered(category, line, event) {
        const {round, onChange} = this.props

        round.delivered[category] = round.delivered[category] || {}
        round.delivered[category][line] = round.delivered[category][line] || 0
        if (round.delivered[category][line] > 0) {
            round.delivered[category][line] -= 1
        }

        onChange(round)
    }

    onClickAddDelivered(category, line, event) {
        const {round, onChange} = this.props

        round.delivered[category] = round.delivered[category] || {}
        round.delivered[category][line] = round.delivered[category][line] || 0
        if (round.delivered[category][line] < 999) {
            round.delivered[category][line] += 1
        }

        onChange(round)
    }

    onChangeDelivered(category, line, event) {
        const {round, onChange} = this.props

        if (/^$|^[0-9]{0,5}$/.test(event.target.value)) {
            round.delivered[category] = round.delivered[category] || {}
            round.delivered[category][line] = round.delivered[category][line] || 0
            round.delivered[category][line] = parseInt(event.target.value) || 0

            onChange(round)
        }
    }


    onClickMinNotDelivered(category, line, event) {
        const {round, onChange} = this.props

        round.notDelivered[category] = round.notDelivered[category] || {}
        round.notDelivered[category][line] = round.notDelivered[category][line] || 0
        if (round.notDelivered[category][line] > 0) {
            round.notDelivered[category][line] -= 1
        }

        onChange(round)
    }

    onClickAddNotDelivered(category, line, event) {
        const {round, onChange} = this.props

        round.notDelivered[category] = round.notDelivered[category] || {}
        round.notDelivered[category][line] = round.notDelivered[category][line] || 0
        if (round.notDelivered[category][line] < round.delivered[category][line]) {
            round.notDelivered[category][line] += 1
        }

        onChange(round)
    }

    onChangeNotDelivered(category, line, event) {
        const {round, onChange} = this.props

        if (/^$|^[0-9]{0,5}$/.test(event.target.value)) {
            round.notDelivered[category] = round.notDelivered[category] || {}
            round.notDelivered[category][line] = round.notDelivered[category][line] || 0

            if ((parseInt(event.target.value) || 0) <= round.delivered[category][line]) {
                round.notDelivered[category][line] = parseInt(event.target.value) || 0
            }

            onChange(round)
        }
    }


    render() {
        const {round, priceTable} = this.props

        return (
            <>
                {priceTable.categories.map((category) => {
                    return (
                        <div key={category.name}>
                            <h6>{category.name}</h6>

                            {category.lines.map((line) => {
                                return (
                                    <div key={line.name} style={{display: 'flex', width: '100%', marginBottom: 3}}>
                                        <div style={{display: 'flex', alignItems: 'center', flex: 1}}>
                                            <div style={{flex: 2, marginRight: 10}}>{line.name}</div>

                                            <IconButton style={{color: Colors.primary, background: 'white'}} onClick={this.onClickMinDelivered.bind(this, category.name, line.name)}>
                                                <i className='mdi mdi-minus'/>
                                            </IconButton>

                                            <Form.Control
                                                style={{width: 60, marginRight: 5, marginLeft: 5}}
                                                value={round.delivered[category.name] && round.delivered[category.name][line.name] ? round.delivered[category.name][line.name].toString() || '' : ''}
                                                onChange={this.onChangeDelivered.bind(this, category.name, line.name)}
                                            />

                                            <IconButton style={{color: Colors.primary, background: 'white'}} onClick={this.onClickAddDelivered.bind(this, category.name, line.name)}>
                                                <i className='mdi mdi-plus'/>
                                            </IconButton>
                                        </div>

                                        <div style={{display: 'flex', alignItems: 'center', flex: 1}}>
                                            {!!round.delivered[category.name] && !!round.delivered[category.name][line.name] &&
                                                <>
                                                    <IconButton style={{color: Colors.primary, background: 'white'}} onClick={this.onClickMinNotDelivered.bind(this, category.name, line.name)}>
                                                        <i className='mdi mdi-minus'/>
                                                    </IconButton>

                                                    <Form.Control
                                                        style={{width: 60, marginRight: 5, marginLeft: 5}}
                                                        value={round.notDelivered[category.name] && round.notDelivered[category.name][line.name] ? round.notDelivered[category.name][line.name].toString() || '' : ''}
                                                        onChange={this.onChangeNotDelivered.bind(this, category.name, line.name)}
                                                    />

                                                    <IconButton style={{color: Colors.primary, background: 'white'}} onClick={this.onClickAddNotDelivered.bind(this, category.name, line.name)}>
                                                        <i className='mdi mdi-plus'/>
                                                    </IconButton>
                                                </>
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
            </>
        )
    }
}

export default Radium(DeliveryTable)
