module.exports = () => {
    return JSON.parse(JSON.stringify(
        {
            name: 'Nieuwe personeelskostenstaffel',
            age: [0, 0, 0, 0],
            years: [0, 0, 0, 0, 0],
            zzpSalary: 0
        }
    ))
}
