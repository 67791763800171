import Reflux from 'reflux'
import moment from 'moment'
import socket from '../../socketio/socketio.js'

import ResultActions from '../actions/ResultActions.js'

export default class ResultStore extends Reflux.Store {
    constructor() {
        super()

        this.state = {
            startDate: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            customerName: '',
            userName: ''
        }

        this.listenables = ResultActions
    }


    onSetStartDate(startDate) {
        this.setState({startDate})
    }

    onSetEndDate(endDate) {
        this.setState({endDate})
    }

    onSetCustomer(customerName) {
        this.setState({customerName})
    }

    onSetUser(userName) {
        this.setState({userName})
    }


    onGetStatistics(callback) {
        const {startDate, endDate} = this.state
        socket.emit('results.getStatistics', sessionStorage.token, startDate, endDate, callback)
    }


    onGetCustomerSpecificationCSV(type, callback) {
        const {customerName, startDate, endDate} = this.state
        socket.emit('results.getCustomerSpecificationCSV', sessionStorage.token, customerName, startDate, endDate, type, callback)
    }

    onGetCustomerResultCSV(callback) {
        const {customerName, startDate, endDate} = this.state
        socket.emit('results.getCustomerResultCSV', sessionStorage.token, customerName, startDate, endDate, callback)
    }

    onGetCustomerInvoiceCSV(callback) {
        const {customerName, startDate, endDate} = this.state
        socket.emit('results.getCustomerInvoiceCSV', sessionStorage.token, customerName, startDate, endDate, callback)
    }

    onGetCustomerInvoicePDF(callback) {
        const {customerName, startDate, endDate} = this.state
        socket.emit('results.getCustomerInvoicePDF', sessionStorage.token, customerName, startDate, endDate, callback)
    }


    onGetUserResultCSV(callback) {
        const {userName, startDate, endDate} = this.state
        socket.emit('results.getUserResultCSV', sessionStorage.token, userName, startDate, endDate, callback)
    }

    onGetUserSalaryCSV(callback) {
        const {userName, startDate, endDate} = this.state
        socket.emit('results.getUserSalaryCSV', sessionStorage.token, userName, startDate, endDate, callback)
    }

    onGetRevenueCSV(callback) {
        const {startDate, endDate} = this.state
        socket.emit('results.getRevenueCSV', sessionStorage.token, startDate, endDate, callback)
    }
}
