import React from 'react'
import Radium from 'radium'
import {Form, InputGroup, Overlay, Popover} from 'react-bootstrap'
import {IconButton, Colors} from '../UI'

const amHours = ['12', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11']
const pmHours = ['00', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']
const minutes = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55']

class TimePicker extends React.Component {
    constructor(props) {
        super(props)

        const {time} = props

        const displayHour = time.split(':')[0] || '12'
        const displayMinute = time.split(':')[1] || '00'

        this.state = {
            target: null,
            show: false,
            inputFocused: false,
            timeout: null,
            view: 'hour',
            time,
            displayHour,
            displayMinute
        }
    }

    componentDidUpdate(prevProps) {
        const {time} = this.props
        if (prevProps.time !== time) {
            let displayHour = time.split(':')[0] || '12'
            let displayMinute = time.split(':')[1] || '00'

            displayHour = displayHour.length === 1 ? `0${displayHour}` : displayHour.substr(0, 2)
            displayMinute = displayMinute.length === 1 ? `0${displayMinute}` : displayMinute.substr(0, 2)

            this.setState({time, displayHour, displayMinute})
        }
    }

    componentWillUnmount() {
        clearTimeout(this.state.timeout)
    }

    handleFocus(event) {
        this.setState({
            target: this.formControl,
            show: true,
            inputFocused: true
        })
    }

    handleBlur(event) {
        this.setState({
            inputFocused: false,
            timeout: setTimeout(() => {
                const {time} = this.props

                if (!this.state.inputFocused) {
                    this.setState({time, target: null, show: false, view: 'hour'})
                }
            }, 100)
        })
    }

    handleChangeInput(event) {
        const {onChange} = this.props

        let time = event.target.value.replace('.', ':').replace(',', ':')

        if (this.state.time.length === 1 && time.length === 2) {
            time = `${time}:`
        }

        if (/^$|^[0-9]{0,2}[:]?[0-9]{0,2}$/.test(time)) {
            if (time.indexOf(':') > -1) {
                this.setState({view: 'minute'})
            } else {
                this.setState({view: 'hour'})
            }
            this.setState({time})

            if (/^([0-1][0-9]|2[0-3]):[0-5][0-9]$/.test(time)) {
                onChange(event)
            }
        }
    }

    onClickSetView(view, event) {
        this.formControl.focus()
        this.setState({view})
    }

    handleClickHour(hour, event) {
        this.formControl.focus()
        const {time, onChange} = this.props

        hour = hour.length === 1 ? `0${hour}`: hour
        const minute = time.split(':')[1] || '00'

        this.setState({view: 'minute'})
        event.target.value = `${hour}:${minute}`
        onChange(event)
    }

    handleHoverHour(displayHour, event) {
        displayHour = displayHour.length === 1 ? `0${displayHour}`: displayHour
        this.setState({displayHour})
    }

    handleClickMinute(minute, event) {
        const {time, onChange} = this.props
        const hour = time.split(':')[0] || '12'

        this.formControl.blur()
        event.target.value = `${hour}:${minute}`
        onChange(event)
    }

    handleHoverMinute(displayMinute, event) {
        this.setState({displayMinute})
    }

    handleClickAddMinute(event) {
        this.formControl.focus()
        const {displayMinute} = this.state
        const {time, onChange} = this.props
        const hour = time.split(':')[0] || '12'

        let minute = parseInt(displayMinute) + 1

        if (minute > 59) {
            minute = 0
        }

        minute = minute.toString().length === 1 ? `0${minute}`: minute.toString()

        this.setState({displayMinute: minute})
        event.target.value = `${hour}:${minute}`
        onChange(event)
    }

    handleClickSubtractMinute(event) {
        this.formControl.focus()
        const {displayMinute} = this.state
        const {time, onChange} = this.props
        const hour = time.split(':')[0] || '12'

        let minute = parseInt(displayMinute) - 1

        if (minute < 0) {
            minute = 59
        }

        minute = minute.toString().length === 1 ? `0${minute}`: minute.toString()

        this.setState({displayMinute: minute})
        event.target.value = `${hour}:${minute}`
        onChange(event)
    }

    onMouseDownPopover() {
        setTimeout(() => {
            this.formControl.focus()
        }, 20)
    }

    render() {
        const {target, show, view, displayHour, displayMinute} = this.state
        const {time, size, style} = this.props

        const hour = time.split(':')[0] || '12'
        const minute = time.split(':')[1] || '00'

        const increase = Math.PI * 2 / 12
        let amAngle = Math.PI / 2
        let pmAngle = Math.PI / 2

        const minuteIncrease = Math.PI * 2 / minutes.length
        let minuteAngle = Math.PI / 2

        return (
            <div style={style}>
                <InputGroup size={size}>
                    <Form.Control
                        ref={(ref) => this.formControl = ref}
                        value={this.state.time}
                        pattern='[0-9]{2}:[0-9]{2}'
                        title='HH:mm'
                        onFocus={this.handleFocus.bind(this)}
                        onBlur={this.handleBlur.bind(this)}
                        onChange={this.handleChangeInput.bind(this)}
                    />

                    <InputGroup.Append>
                        <InputGroup.Text>
                            <i style={{fontSize: '0.9em'}} className='mdi mdi-clock-outline'/>
                        </InputGroup.Text>
                    </InputGroup.Append>
                </InputGroup>

                <Overlay
                    show={show}
                    target={target}
                    placement='auto'
                >
                    <Popover onMouseDown={this.onMouseDownPopover.bind(this)}>
                        <Popover.Content>
                            {view === 'hour' &&
                                <div style={{display: 'flex', width: 250, height: 250, justifyContent: 'center', alignItems: 'center'}}>
                                    <div style={{position: 'relative', bottom: 110, left: 133, width: 0}}>
                                        <IconButton
                                            onClick={this.onClickSetView.bind(this, 'minute')}
                                        >
                                            <i className='mdi mdi-arrow-right'/>
                                        </IconButton>
                                    </div>
                                    <div style={{width: 75}}>
                                        <div style={{display: 'flex', justifyContent: 'center'}}>
                                            <h3 style={{color: Colors.primary}}>{`${displayHour}:`}</h3>
                                            <h3 >{minute}</h3>
                                        </div>
                                    </div>

                                    {amHours.map((clockHour, index) => {
                                        const right = 100 * Math.cos(amAngle) + 115
                                        const bottom = 100 * Math.sin(amAngle) + 115
                                        amAngle += increase

                                        return (
                                            <div
                                                key={`amHour${clockHour}`}
                                                style={{
                                                    position: 'absolute',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    right,
                                                    bottom,
                                                    cursor: 'pointer',
                                                    borderRadius: '50%',
                                                    width: 35,
                                                    height: 35,
                                                    background: clockHour === hour ? Colors.primary : 'transparent',
                                                    color: clockHour === hour ? 'white' : 'black',
                                                    ':hover': {background: clockHour === hour ? Colors.primary : Colors.lightGrey}
                                                }}
                                                onClick={this.handleClickHour.bind(this, clockHour)}
                                                onMouseOver={this.handleHoverHour.bind(this, clockHour)}
                                            >
                                                {clockHour}
                                            </div>
                                        )
                                    })}

                                    {pmHours.map((clockHour, index) => {
                                        const right = 65 * Math.cos(pmAngle) + 115
                                        const bottom = 65 * Math.sin(pmAngle) + 115
                                        pmAngle += increase

                                        return (
                                            <div
                                                key={`pmHour${clockHour}`}
                                                style={{
                                                    position: 'absolute',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    right,
                                                    bottom,
                                                    cursor: 'pointer',
                                                    borderRadius: '50%',
                                                    width: 35,
                                                    height: 35,
                                                    fontSize: '0.9em',
                                                    background: clockHour === hour ? Colors.primary : 'transparent',
                                                    color: clockHour === hour ? 'white' : 'black',
                                                    ':hover': {background: clockHour === hour ? Colors.primary : Colors.lightGrey}
                                                }}
                                                onClick={this.handleClickHour.bind(this, clockHour)}
                                                onMouseOver={this.handleHoverHour.bind(this, clockHour)}
                                            >
                                                {clockHour}
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                            {view === 'minute' &&
                                <div style={{display: 'flex', width: 250, height: 250, justifyContent: 'center', alignItems: 'center'}}>
                                    <div style={{position: 'relative', bottom: 110, right: 95, width: 0}}>
                                        <IconButton
                                            onClick={this.onClickSetView.bind(this, 'hour')}
                                        >
                                            <i className='mdi mdi-arrow-left'/>
                                        </IconButton>
                                    </div>
                                    <div style={{width: 75}}>
                                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                            <h1 style={{margin: 0, padding: 0}}>{`${hour}:`}</h1>
                                            <div>
                                                <IconButton
                                                    key='chevron-up'
                                                    style={{marginLeft: 5}}
                                                    onClick={this.handleClickAddMinute.bind(this)}
                                                >
                                                    <i className='mdi mdi-chevron-up'/>
                                                </IconButton>
                                                <h1 style={{margin: 0, padding: 0, color: Colors.primary}}>{displayMinute}</h1>
                                                <IconButton
                                                    key='chevron-down'
                                                    style={{marginLeft: 5}}
                                                    onClick={this.handleClickSubtractMinute.bind(this)}
                                                >
                                                    <i className='mdi mdi-chevron-down'/>
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                                    {minutes.map((clockMinute, index) => {
                                        const right = 100 * Math.cos(minuteAngle) + 115
                                        const bottom = 100 * Math.sin(minuteAngle) + 115
                                        minuteAngle += minuteIncrease

                                        return (
                                            <div
                                                key={`minute${clockMinute}`}
                                                style={{
                                                    position: 'absolute',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    right,
                                                    bottom,
                                                    cursor: 'pointer',
                                                    borderRadius: '50%',
                                                    width: 35,
                                                    height: 35,
                                                    background: clockMinute === minute ? Colors.primary : 'transparent',
                                                    color: clockMinute === minute ? 'white' : 'black',
                                                    ':hover': {background: clockMinute === minute ? Colors.primary : Colors.lightGrey}
                                                }}
                                                onClick={this.handleClickMinute.bind(this, clockMinute)}
                                                onMouseOver={this.handleHoverMinute.bind(this, clockMinute)}
                                            >
                                                {clockMinute}
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                        </Popover.Content>
                    </Popover>
                </Overlay>
            </div>
        )
    }
}

export default Radium(TimePicker)
