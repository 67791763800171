import React from 'react'
import Radium from 'radium'
import _ from 'underscore'

import SettingsActions from '../../actions/SettingsActions.js'

import {Alert, Button, Card, Form} from 'react-bootstrap'
import {Price} from '../forms'
import {IconButton, Popup} from '../UI'


class PriceTable extends React.Component {
    constructor(props) {
        super(props)

        this.state ={
            priceTable: JSON.parse(JSON.stringify((props.priceTable))),
            edit: false,
            message: '',
            error: ''
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.priceTable) !== JSON.stringify(prevProps.priceTable)) {
            this.setState(({priceTable: JSON.parse(JSON.stringify(this.props.priceTable)), message: '', error: ''}))
        }
    }

    onChangeName(event) {
        const priceTable = JSON.parse(JSON.stringify(this.state.priceTable))
        priceTable.name = event.target.value
        this.setState({priceTable})
    }

    onClickAddQuantity() {
        const {priceTable} = this.state
        priceTable.quantities.push(0)
        this.setState({priceTable})
    }

    onClickRemoveQuantity() {
        const {priceTable} = this.state
        priceTable.quantities.pop()
        this.setState({priceTable})
    }

    onChangeQuantity(index, event) {
        const {priceTable} = this.state
        priceTable.quantities[index] = parseInt(event.target.value) || ''
        this.setState({priceTable})
    }


    onChangeCategoryName(categoryIndex, event) {
        const priceTable = JSON.parse(JSON.stringify(this.state.priceTable))
        priceTable.categories[categoryIndex].name = event.target.value
        this.setState({priceTable})
    }

    onClickRemoveCategory(categoryIndex) {
        const {priceTable} = this.state
        priceTable.categories.splice(categoryIndex, 1)
        this.setState({priceTable})
    }

    onClickAddCategory() {
        const {priceTable} = this.state
        priceTable.categories.push({name: '', lines: [{name: '', rates: []}]})
        this.setState({priceTable})
    }


    onChangeLineName(categoryIndex, lineIndex, event) {
        const priceTable = JSON.parse(JSON.stringify(this.state.priceTable))
        priceTable.categories[categoryIndex].lines[lineIndex].name = event.target.value
        this.setState({priceTable})
    }

    onClickRemoveLine(categoryIndex, lineIndex) {
        const {priceTable} = this.state
        priceTable.categories[categoryIndex].lines.splice(lineIndex, 1)
        this.setState({priceTable})
    }

    onClickAddLine(categoryIndex) {
        const {priceTable} = this.state
        priceTable.categories[categoryIndex].lines.push({name: '', rates: []})
        this.setState({priceTable})
    }


    onChangeRate(categoryIndex, lineIndex, rateIndex, event) {
        const {priceTable} = this.state
        priceTable.categories[categoryIndex].lines[lineIndex].rates[rateIndex] = parseFloat(event.target.value)
        this.setState({priceTable})
    }

    onChangeCallOutFeeTill(index, event) {
        const {priceTable} = this.state
        priceTable.callOutFees[index].till = parseInt(event.target.value) || ''
        this.setState({priceTable})
    }

    onChangeCallOutFeePrice(index, event) {
        const {priceTable} = this.state
        priceTable.callOutFees[index].price = parseFloat(event.target.value)
        this.setState({priceTable})
    }

    onClickAddCallOutFee(index, event) {
        const {priceTable} = this.state
        priceTable.callOutFees.push({till: 10, price: 0})
        this.setState({priceTable})
    }

    onClickRemoveCallOutFee(index, event) {
        const {priceTable} = this.state
        priceTable.callOutFees.splice(index, 1)
        this.setState({priceTable})
    }

    onChangeValue(key, event) {
        const {priceTable} = this.state
        priceTable[key] = parseFloat(event.target.value)
        this.setState({priceTable})
    }


    onClickSave() {
        const {priceTable} = this.state

        priceTable.callOutFees = _.sortBy(priceTable.callOutFees, 'till')

        this.setState({message: '', error: ''})

        SettingsActions.updatePriceTable(priceTable, (err) => {
            if (err) {
                this.setState({error: err})
            } else {
                this.setState({edit: false, message: 'Wijzigingen opgeslagen'})
            }
        })
    }

    onClickRemove() {
        const {priceTable} = this.state

        this.popup.open('Verwijder Tarieftabel', '', () => {
            SettingsActions.removePriceTable(priceTable._id, (err) => {
                if (err) {
                    this.popup.setError(err)
                }
            })
        })
    }

    render() {
        const {priceTable, edit, message, error} = this.state

        if (!priceTable) {
            return null
        }

        return (
            <Card style={{marginTop: 20}}>
                <Card.Header>
                    <Card.Title>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            {edit ?
                                <Form.Control style={{width: 300}} value={priceTable.name} onChange={this.onChangeName.bind(this)}/> :
                                <h5>{priceTable.name}</h5>
                            }
                        </div>
                    </Card.Title>
                </Card.Header>

                <Card.Body>
                    <br/>
                    <br/>
                    <div style={{display: 'flex', marginLeft: 200}}>
                        {priceTable.quantities.map((quantity, quantityIndex) => {
                            return (
                                <div key={`quantity${quantityIndex}`} style={{display: 'flex', alignItems: 'center', width: 125, marginLeft: 20}}>
                                    <h6>{`${(priceTable.quantities[quantityIndex - 1] + 1) || '0'} t/m ${!edit ? quantity : ''}`}</h6>

                                    {edit &&
                                        <Form.Control
                                            style={{marginLeft: 5, width: 60}}
                                            size='sm' type='number' value={quantity}
                                            onChange={this.onChangeQuantity.bind(this, quantityIndex)}
                                        />
                                    }
                                </div>
                            )
                        })}

                        {edit &&
                            <>
                                <IconButton onClick={this.onClickRemoveQuantity.bind(this)}><i className='mdi mdi-delete'/></IconButton>
                                <IconButton onClick={this.onClickAddQuantity.bind(this)}><i className='mdi mdi-plus'/></IconButton>
                            </>
                        }
                    </div>

                    {priceTable.categories.map((category, categoryIndex) => {
                        return (
                            <div key={`category${categoryIndex}`}>

                                {edit ?
                                    <div style={{display: 'flex'}}>
                                        <Form.Group style={{width: 200}}>
                                            <Form.Control value={category.name} onChange={this.onChangeCategoryName.bind(this, categoryIndex)}/>
                                        </Form.Group>

                                        {categoryIndex > 0 &&
                                            <IconButton onClick={this.onClickRemoveCategory.bind(this, categoryIndex)}>
                                                <i className='mdi mdi-delete'/>
                                            </IconButton>
                                        }
                                    </div> :
                                    <h6>{category.name}</h6>
                                }

                                {category.lines.map((line, lineIndex) => {
                                    return (
                                        <div key={`line${lineIndex}`} style={{display: 'flex'}}>
                                            {edit ?
                                                <Form.Group style={{width: 200}}>
                                                    <Form.Control value={line.name} onChange={this.onChangeLineName.bind(this, categoryIndex, lineIndex)}/>
                                                </Form.Group> :
                                                <p style={{width: 200}}>{line.name}</p>
                                            }

                                            {priceTable.quantities.map((quantity, rateIndex) => {
                                                return (
                                                    <div key={`rate${rateIndex}`} style={{width: 125, marginLeft: 20}}>
                                                        {edit ?
                                                            <Price

                                                                value={line.rates[rateIndex] || 0}
                                                                onChange={this.onChangeRate.bind(this, categoryIndex, lineIndex, rateIndex)}
                                                            />:
                                                            <p>{`€ ${(line.rates[rateIndex] || 0).toFixed(2).replace('.', ',')}`}</p>
                                                        }
                                                    </div>
                                                )
                                            })}
                                            {edit && lineIndex > 0 &&
                                                <IconButton onClick={this.onClickRemoveLine.bind(this, categoryIndex, lineIndex)}>
                                                    <i className='mdi mdi-delete'/>
                                                </IconButton>
                                            }

                                            {edit && lineIndex === category.lines.length - 1 &&
                                                <IconButton onClick={this.onClickAddLine.bind(this, categoryIndex)}>
                                                    <i className='mdi mdi-plus'/>
                                                </IconButton>
                                            }
                                        </div>
                                    )
                                })}

                                {edit && categoryIndex === priceTable.categories.length - 1 &&
                                    <IconButton onClick={this.onClickAddCategory.bind(this)}>
                                        <i className='mdi mdi-plus'/>
                                    </IconButton>
                                }
                            </div>
                        )
                    })}

                    <br/>
                    <h6 style={{width: 200}}>Voorrijkosten</h6>
                    {priceTable.callOutFees.map((callOutFee, index) => {
                        return (
                            <div key={`${index}callOutFee`} style={{display: 'flex'}}>
                                <p style={{width: 200}}>{`Minder dan ${callOutFee.till} stuks`}</p>

                                <div style={{marginLeft: 20}}>
                                    {edit ?
                                        <div style={{display: 'flex'}}>
                                            <Form.Control
                                                style={{width: 60}}
                                                type='number'
                                                value={callOutFee.till}
                                                onChange={this.onChangeCallOutFeeTill.bind(this, index)}
                                            />
                                            <Price
                                                style={{marginLeft: 5, width: 100}}
                                                value={callOutFee.price || 0}
                                                onChange={this.onChangeCallOutFeePrice.bind(this, index)}
                                            />

                                            {!!index &&
                                                <IconButton onClick={this.onClickRemoveCallOutFee.bind(this, index)}>
                                                    <i className='mdi mdi-delete'/>
                                                </IconButton>
                                            }
                                        </div> :
                                        <p>{`€ ${(callOutFee.price || 0).toFixed(2).replace('.', ',')}`}</p>
                                    }
                                </div>
                            </div>
                        )
                    })}
                    {edit &&
                        <IconButton onClick={this.onClickAddCallOutFee.bind(this)}>
                            <i className='mdi mdi-plus'/>
                        </IconButton>
                    }

                    <br/>
                    <h6 style={{width: 200}}>Overig</h6>
                    <div style={{display: 'flex'}}>
                        <p style={{width: 200}}>Uurtarief fietskoerier</p>

                        <div style={{width: 125, marginLeft: 20}}>
                            {edit ?
                                <Price

                                    value={priceTable.hourPriceBike || 0}
                                    onChange={this.onChangeValue.bind(this, 'hourPriceBike')}
                                />:
                                <p>{`€ ${(priceTable.hourPriceBike || 0).toFixed(2).replace('.', ',')}`}</p>
                            }
                        </div>
                    </div>

                    <div style={{display: 'flex'}}>
                        <p style={{width: 200}}>Uurtarief autobezorging</p>

                        <div style={{width: 125, marginLeft: 20}}>
                            {edit ?
                                <Price

                                    value={priceTable.hourPriceCar || 0}
                                    onChange={this.onChangeValue.bind(this, 'hourPriceCar')}
                                />:
                                <p>{`€ ${(priceTable.hourPriceCar || 0).toFixed(2).replace('.', ',')}`}</p>
                            }
                        </div>
                    </div>

                    <div style={{display: 'flex'}}>
                        <p style={{width: 200}}>Vergoeding per km</p>

                        <div style={{width: 125, marginLeft: 20}}>
                            {edit ?
                                <Price

                                    value={priceTable.distanceFee || 0}
                                    onChange={this.onChangeValue.bind(this, 'distanceFee')}
                                />:
                                <p>{`€ ${(priceTable.distanceFee || 0).toFixed(2).replace('.', ',')}`}</p>
                            }
                        </div>
                    </div>

                    <div style={{display: 'flex'}}>
                        <p style={{width: 200}}>Toeslag koeltransport</p>

                        <div style={{width: 125, marginLeft: 20}}>
                            {edit ?
                                <Price

                                    value={priceTable.cooledTransportFee || 0}
                                    onChange={this.onChangeValue.bind(this, 'cooledTransportFee')}
                                />:
                                <p>{`€ ${(priceTable.cooledTransportFee || 0).toFixed(2).replace('.', ',')}`}</p>
                            }
                        </div>
                    </div>

                    <div style={{display: 'flex'}}>
                        <p style={{width: 200}}>Wachttijd per minuut</p>

                        <div style={{width: 125, marginLeft: 20}}>
                            {edit ?
                                <Price

                                    value={priceTable.waitTime || 0}
                                    onChange={this.onChangeValue.bind(this, 'waitTime')}
                                />:
                                <p>{`€ ${(priceTable.waitTime || 0).toFixed(2).replace('.', ',')}`}</p>
                            }
                        </div>
                    </div>

                    <div style={{display: 'flex'}}>
                        <p style={{width: 200}}>Extra apotheek</p>

                        <div style={{width: 125, marginLeft: 20}}>
                            {edit ?
                                <Price

                                    value={priceTable.extraPharmacy || 0}
                                    onChange={this.onChangeValue.bind(this, 'extraPharmacy')}
                                />:
                                <p>{`€ ${(priceTable.extraPharmacy || 0).toFixed(2).replace('.', ',')}`}</p>
                            }
                        </div>
                    </div>

                    <div style={{display: 'flex'}}>
                        <p style={{width: 200}}>Minimum per dag</p>

                        <div style={{width: 125, marginLeft: 20}}>
                            {edit ?
                                <Price

                                    value={priceTable.minimumPerDay || 0}
                                    onChange={this.onChangeValue.bind(this, 'minimumPerDay')}
                                />:
                                <p>{`€ ${(priceTable.minimumPerDay || 0).toFixed(2).replace('.', ',')}`}</p>
                            }
                        </div>
                    </div>


                    {message &&
                        <Alert variant='success' dismissible onClose={() => this.setState({message: ''})}>{message}</Alert>
                    }
                    {error &&
                        <Alert variant='danger' dismissible onClose={() => this.setState({error: ''})}>{error}</Alert>
                    }
                </Card.Body>

                <Card.Footer>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        {edit ?
                            <Button onClick={this.onClickSave.bind(this)}>Opslaan</Button> :
                            <Button onClick={() => this.setState({edit: true, message: ''})}>Wijzigen</Button>
                        }
                        <Button variant='danger' onClick={this.onClickRemove.bind(this)} >
                            Verwijder
                        </Button>
                    </div>
                </Card.Footer>

                <Popup ref={(ref) => this.popup = ref}/>
            </Card>
        )
    }
}

export default Radium(PriceTable)
