import React from 'react'
import Radium from 'radium'
import moment from 'moment'

import ShiftActions from '../../actions/ShiftActions.js'

import {Alert, Button, Form, InputGroup, Modal, Row, Col, Table} from 'react-bootstrap'
import {Colors, Divider} from '../UI'
import {DatePicker, TimePicker} from '../forms'
import EditRoundModal from './EditRoundModal.js'
import Spinner from 'react-spinkit'

class ShiftModal extends React.Component {
    constructor(props) {
        super(props)

        this.initialState = {
            shift: null,
            modalIsOpen: false,
            edit: false,
            loading: false,
            error: ''
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))
    }

    open(shift) {
        this.setState({
            modalIsOpen: true,
            shift
        })
    }

    close() {
        this.setState(this.initialState)
    }

    onClickSaveShift() {
        const {shift, edit} = this.state

        if (edit) {
            ShiftActions.update(shift, (err, shift) => {
                if (!err) {
                    this.setState({shift})
                } else {
                    this.setState({error: err})
                }
            })
        }

        this.setState({edit: !edit})
    }

    onChangeDate(event) {
        const {shift} = this.state
        shift.date = event.target.value
        this.setState({shift})
    }

    onChangeStartTime(event) {
        const {shift} = this.state

        const hour = parseInt(event.target.value.split(':')[0])
        const minute = parseInt(event.target.value.split(':')[1])

        shift.startTime = moment(shift.date).hour(hour).minute(minute).format()
        this.setState({shift})
    }

    onChangeEndTime(event) {
        const {shift} = this.state

        const hour = parseInt(event.target.value.split(':')[0])
        const minute = parseInt(event.target.value.split(':')[1])

        shift.endTime = moment(shift.date).hour(hour).minute(minute).format()
        this.setState({shift})
    }

    onChangeWait(event) {
        const {shift} = this.state
        shift.totalWait = Math.max((parseInt(event.target.value) || 0) * 60, 0)
        this.setState({shift})
    }

    onChangeBreak(event) {
        const {shift} = this.state
        shift.totalBreak = Math.max((parseInt(event.target.value) || 0) * 60, 0)
        this.setState({shift})
    }

    onChangeUser(event) {
        const {shift} = this.state
        shift.user = event.target.value
        this.setState({shift})
    }

    onChangeInvoiceHours(event) {
        const {shift} = this.state
        shift.invoiceHours = event.target.checked
        shift.type = shift.type || 'bike'
        this.setState({shift})
    }

    onChangeType(event) {
        const {shift} = this.state
        shift.type = event.target.value
        shift.cooledTransport = false
        this.setState({shift})
    }

    onChangeCooledTransport(event) {
        const {shift} = this.state
        shift.cooledTransport = event.target.checked
        this.setState({shift})
    }

    onChangeDistance(event) {
        const {shift} = this.state
        shift.distance = parseInt(event.target.value) || 0
        this.setState({shift})
    }

    onClickEditRound(round) {
        const {shift} = this.state

        ShiftActions.getShiftPriceTable(shift.priceTable, (err, priceTable) => {
            this.editRoundModal.open(round, priceTable)
        })
    }

    onClickSaveRound(round, callback) {
        const {shift} = this.state

        shift.rounds[round.index] = round

        ShiftActions.update(shift, (err, shift) => {
            if (!err) {
                this.setState({shift})
            }
            callback(err, shift)
        })
    }

    render() {
        const {modalIsOpen, shift, edit, loading, error} = this.state
        const {users} = this.props

        if (modalIsOpen && shift) {
            let performanceColor = Colors.success

            if (shift.performance < 100) {
                performanceColor = Colors.warning
            }
            if (shift.performance < 80) {
                performanceColor = Colors.danger
            }

            return (
                <Modal show={modalIsOpen} size="lg" onHide={this.close.bind(this)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Dienst info
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Row>
                            <Col>
                                <p>Datum</p>
                                <Row style={{alignItems: 'center', marginLeft: 0}}>
                                    {!edit ?
                                        <h6>{moment(shift.date).format('DD-MM-YYYY')}</h6> :
                                        <DatePicker
                                            value={shift.date}
                                            onChange={this.onChangeDate.bind(this)}
                                        />
                                    }
                                </Row>
                            </Col>
                            <Col>
                                <p>Klant</p>
                                <h6>{shift.customer}</h6>
                            </Col>
                        </Row>

                        <Divider style={{marginTop: 16, marginBottom: 32}}/>

                        <Row>
                            <Col>
                                <p>Koerier</p>
                                <Row style={{alignItems: 'center', marginLeft: 0}}>
                                    {!edit ?
                                        <h6>{shift.user}</h6> :
                                        <Form.Control
                                            as='select'
                                            style={{width: 250}}
                                            value={shift.user}
                                            onChange={this.onChangeUser.bind(this)}
                                        >
                                            {users.map((user) => {
                                                return <option key={user._id} value={user.name}>{user.name}</option>
                                            })}
                                        </Form.Control>
                                    }
                                </Row>
                            </Col>
                            <Col>
                                <p>Performance</p>
                                <h5 style={{color: performanceColor}}>{`${shift.performance} %`}</h5>
                            </Col>
                        </Row>

                        <Divider style={{marginTop: 16, marginBottom: 32}}/>

                        <Row>
                            <Col>
                                <p>Werktijd</p>
                                <Row style={{alignItems: 'center', marginLeft: 0}}>
                                    {!edit ?
                                        <h6>{`${moment(shift.startTime).format('HH:mm')} - ${moment(shift.endTime).format('HH:mm')}`}</h6> :
                                        <>
                                            <TimePicker style={{width: 120, marginRight: 15}} time={moment(shift.startTime).format('HH:mm')} onChange={this.onChangeStartTime.bind(this)}/>
                                            <TimePicker style={{width: 120}} time={moment(shift.endTime).format('HH:mm')} onChange={this.onChangeEndTime.bind(this)}/>
                                        </>
                                    }
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        <p>Wachttijd</p>
                                        <Row style={{alignItems: 'center', marginLeft: 0}}>
                                            {!edit ?
                                                <h6>{`${parseInt(shift.totalWait / 60)} minuten`}</h6> :
                                                <InputGroup style={{marginRight: 15}}>
                                                    <Form.Control
                                                        type='number'
                                                        value={parseInt(shift.totalWait / 60).toString()}
                                                        onChange={this.onChangeWait.bind(this)}
                                                    />
                                                    <InputGroup.Append>
                                                        <InputGroup.Text>min</InputGroup.Text>
                                                    </InputGroup.Append>
                                                </InputGroup>

                                            }
                                        </Row>
                                    </Col>

                                    <Col>
                                        <p>Pauzetijd</p>
                                        <Row style={{alignItems: 'center', marginLeft: 0}}>
                                            {!edit ?
                                                <h6>{`${parseInt(shift.totalBreak / 60)} minuten`}</h6> :
                                                <InputGroup style={{marginRight: 15}}>
                                                    <Form.Control
                                                        type='number'
                                                        value={parseInt(shift.totalBreak / 60).toString()}
                                                        onChange={this.onChangeBreak.bind(this)}
                                                    />
                                                    <InputGroup.Append>
                                                        <InputGroup.Text>min</InputGroup.Text>
                                                    </InputGroup.Append>
                                                </InputGroup>

                                            }
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Divider style={{marginTop: 16, marginBottom: 32}}/>

                        {edit && !edit &&
                            <Form.Group>
                                <Form.Check
                                    label='Factuur op uurbasis'
                                    checked={shift.invoiceHours}
                                    onChange={this.onChangeInvoiceHours.bind(this)}
                                />
                            </Form.Group>
                        }

                        {shift.invoiceHours &&
                            <Row>
                                <Col>
                                    <p>Type bezorging</p>
                                    <Row style={{alignItems: 'center', marginLeft: 0}}>
                                        <div>
                                            {!edit ?
                                                <h6>{shift.type === 'car' ? 'Autobezorging' : 'Fietskoerier'}</h6> :

                                                <Form.Control
                                                    as='select'
                                                    value={shift.type}
                                                    onChange={this.onChangeType.bind(this)}
                                                >
                                                    <option value='bike'>Fietskoerier</option>
                                                    <option value='car'>Autobezorging</option>
                                                </Form.Control>
                                            }
                                            {shift.type === 'car' &&
                                                <Form.Group>
                                                    <Form.Check
                                                        label='Gekoeld transport'
                                                        checked={!!shift.cooledTransport}
                                                        onChange={edit ? this.onChangeCooledTransport.bind(this) : () => {}}
                                                    />
                                                </Form.Group>
                                            }
                                        </div>
                                    </Row>
                                </Col>

                                {shift.distanceFee ?
                                    <Col>
                                        <p>Afstand</p>
                                        <Row style={{alignItems: 'center', marginLeft: 0}}>
                                            {!edit ?
                                                <h6>{`${shift.distance} km`}</h6> :

                                                <InputGroup style={{marginRight: 15}}>
                                                    <Form.Control
                                                        type='number'
                                                        value={shift.distance}
                                                        onChange={this.onChangeDistance.bind(this)}
                                                    />
                                                    <InputGroup.Append>
                                                        <InputGroup.Text>km</InputGroup.Text>
                                                    </InputGroup.Append>
                                                </InputGroup>
                                            }
                                        </Row>
                                    </Col> :
                                    <Col/>
                                }
                            </Row>
                        }

                        <br/>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Starttijd</th>
                                    <th>Eindtijd</th>
                                    <th>Bezorgadres</th>
                                    <th>Niet thuis</th>
                                    <th/>
                                </tr>
                            </thead>
                            <tbody>
                                {shift.rounds.map((round, index) => {
                                    return (
                                        <tr key={`round${index}`}>
                                            <td>{index + 1}</td>
                                            <td>{moment(round.startTime).format('HH:mm')}</td>
                                            <td>{moment(round.endTime).format('HH:mm')}</td>
                                            <td>{round.nrDelivered}</td>
                                            <td>{round.nrNotDelivered}</td>
                                            <td>
                                                <Button size='sm' onClick={this.onClickEditRound.bind(this, round)}>
                                                    <i className='mdi mdi-pencil'/>
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td/>
                                    <td/>
                                    <th>Totaal</th>
                                    <td>{shift.nrDelivered}</td>
                                    <td>{shift.nrNotDelivered}</td>
                                    <td/>
                                </tr>
                            </tbody>
                        </Table>

                        {shift.feedback &&
                            <>
                                <Divider style={{marginTop: 16, marginBottom: 32}}/>

                                <h6>Feedback</h6>
                                <p>{shift.feedback}</p>
                            </>
                        }

                        {error &&
                            <Alert variant='danger'>{error}</Alert>
                        }
                    </Modal.Body>

                    <Modal.Footer>

                        <Button
                            disabled={loading}
                            onClick={this.onClickSaveShift.bind(this)}
                        >
                            {loading ?
                                <Spinner fadeIn='none'/> :
                                !edit ?
                                    <i className='mdi mdi-pencil'/> :
                                    'Opslaan'
                            }
                        </Button>
                    </Modal.Footer>

                    <EditRoundModal ref={(ref) => this.editRoundModal = ref} onChange={this.onClickSaveRound.bind(this)}/>
                </Modal>
            )
        } else {
            return <div/>
        }
    }
}

export default Radium(ShiftModal)
