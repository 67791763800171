import Reflux from 'reflux'
import _ from 'underscore'
import socket from '../../socketio/socketio.js'
import filter from '../utils/filter.js'

import CustomerActions from '../actions/CustomerActions.js'

export default class CustomerStore extends Reflux.Store {
    constructor() {
        super()

        this.state = {
            unfilteredCustomers: [],
            customers: [],
            selectedCustomers: [],
            customersLoading: false,
            customersSortKey: 'name',
            customersSortReverse: false,
            customerQueries: []
        }

        this.listenables = CustomerActions

        CustomerActions.get(true)

        socket.on('updateCustomers', () => {
            CustomerActions.get()
        })
    }

    onChangeQueries(customerQueries) {
        const {unfilteredCustomers} = this.state

        const customers = filter.customers(unfilteredCustomers, customerQueries)

        this.setState({customers, customerQueries})
    }

    onChangeSortKey(newSortKey) {
        let {customers, customersSortKey, customersSortReverse} = this.state

        if (customersSortKey === newSortKey) {
            customersSortReverse = !customersSortReverse
        } else {
            customersSortReverse = false
        }

        customersSortKey = newSortKey

        customers = _.sortBy(customers, customersSortKey)

        if (customersSortReverse) {
            customers.reverse()
        }

        this.setState({customers, customersSortKey, customersSortReverse})
    }

    onSetSelected(selectedCustomers) {
        this.setState({selectedCustomers})
    }

    onGet(loading) {
        this.setState({customersLoading: !!loading})

        socket.emit('customers.get', sessionStorage.token, (err, unfilteredCustomers) => {
            if (!err) {
                const {customerQueries, customersSortKey, customersSortReverse} = this.state

                let customers = filter.customers(unfilteredCustomers, customerQueries)

                customers = _.sortBy(customers, customersSortKey)
                if (customersSortReverse) {
                    customers.reverse()
                }

                this.setState({unfilteredCustomers, customers, customersLoading: false})
            }
        })
    }

    onUpdate(customer, callback) {
        socket.emit('customers.update', sessionStorage.token, customer, callback)
    }

    onRemove(id, callback) {
        socket.emit('customers.remove', sessionStorage.token, id, callback)
    }
}
