import Reflux from 'reflux'

export default Reflux.createActions([
    'setStartDate',
    'setEndDate',
    'setCustomer',
    'setUser',
    'getStatistics',
    'getCustomerSpecificationCSV',
    'getCustomerResultCSV',
    'getCustomerInvoiceCSV',
    'getCustomerInvoicePDF',
    'getUserResultCSV',
    'getUserSalaryCSV',
    'getRevenueCSV'
])
