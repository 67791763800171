import React from 'react'
import {render} from 'react-dom'
import {BrowserRouter, Route, Switch} from 'react-router-dom'

import Login from './views/Login.js'
import Layout from './views/Layout.js'

import ForgotPassword from './views/ForgotPassword.js'
import ResetPassword from './views/ResetPassword.js'

const router = (
    <BrowserRouter>
        <Switch>
            <Route path="/wachtwoordvergeten" component={ForgotPassword}/>
            <Route path="/wachtwoordwijzigen/:token" component={ResetPassword}/>

            <Route path="/login" component={Login}/>
            <Route path="/" component={Layout}/>
        </Switch>
    </BrowserRouter>
)

render(router, document.getElementById('react-container'))
