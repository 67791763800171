import React from 'react'
import Radium from 'radium'

import SearchBox from './SearchBox.js'
import SearchQuery from './SearchQuery.js'

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column'

    },
    topBar: {
        display: 'flex'
    },
    bottomBar: {
        display: 'flex',
        minHeight: 30,
        width: 365
    }
}

class SearchBar extends React.Component {
    addQuery(query) {
        const {queries, onChange} = this.props
        queries.push(query)
        onChange(queries)
    }

    removeQuery(i) {
        const {queries, onChange} = this.props
        queries.splice(i, 1)
        onChange(queries)
    }

    renderQueries() {
        const {queries} = this.props

        return queries.map((query, i) => {
            return (
                <SearchQuery
                    key={i}
                    query={query}
                    onRemove={this.removeQuery.bind(this, i)}
                />
            )
        })
    }

    render() {
        const {columns} = this.props

        return (
            <div style={styles.container}>
                <div style={styles.topBar}>
                    <SearchBox columns={columns} onChange={this.addQuery.bind(this)}/>
                </div>
                <div style={styles.bottomBar}>
                    {this.renderQueries()}
                </div>
            </div>
        )
    }
}

export default Radium(SearchBar)
