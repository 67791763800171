module.exports = () => {
    return JSON.parse(JSON.stringify(
        {
            name: 'Nieuwe tariefstaffel',
            quantities: [0],
            categories: [
                {
                    name: 'Categorie 1',
                    lines: [
                        {
                            name: 'Regel 1',
                            rates: []
                        }

                    ]

                }

            ],
            waitTime: 0,
            extraPharmacy: 0,
            callOutFees: [{till: 10, price: 0}],
            hourPriceBike: 0,
            hourPriceCar: 0,
            distanceFee: 0,
            cooledTransportFee: 0,
            minimumPerDay: 0
        }
    ))
}
