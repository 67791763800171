import Reflux from 'reflux'

export default Reflux.createActions([
    'changeQueries',
    'changeSortKey',
    'setSelected',
    'get',
    'update',
    'remove'
])
