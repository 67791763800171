import React from 'react'
import Reflux from 'reflux'
import Radium from 'radium'

import SettingsStore from '../stores/SettingsStore.js'
import SettingsActions from '../actions/SettingsActions.js'

import NewPriceTable from '../components/priceTables/NewPriceTable.js'
import PriceTable from '../components/priceTables/PriceTable.js'
import NewSalaryTable from '../components/salaryTables/NewSalaryTable.js'
import SalaryTable from '../components/salaryTables/SalaryTable.js'
import NewMessageSet from '../components/messageSets/NewMessageSet.js'
import MessageSet from '../components/messageSets/MessageSet.js'

import {Alert, Button, Card, ListGroup, Tabs, Tab} from 'react-bootstrap'

class Settings extends Reflux.Component {
    constructor(props) {
        super(props)

        this.state = {
            tab: 'priceTables',
            message: '',
            error: '',
            loading: false
        }

        this.store = SettingsStore
    }

    onClickSelectPriceTable(priceTable) {
        SettingsActions.selectPriceTable(priceTable)
    }

    onClickNewPriceTable() {
        SettingsActions.updatePriceTable(NewPriceTable())
    }


    onClickSelectSalaryTable(salaryTable) {
        SettingsActions.selectSalaryTable(salaryTable)
    }

    onClickNewSalaryTable() {
        SettingsActions.updateSalaryTable(NewSalaryTable())
    }


    onClickSelectMessageSet(messageSet) {
        SettingsActions.selectMessageSet(messageSet)
    }

    onClickNewMessageSet() {
        SettingsActions.updateMessageSet(NewMessageSet())
    }

    render() {
        const {tab, priceTables, priceTable, salaryTables, salaryTable, messageSets, messageSet, message, error} = this.state
        const {user} = this.props

        if (!user || user.type !== 'Admin') {
            return <div/>
        }

        return (
            <div style={{display: 'flex', flexDirection: 'column', height: '100%', width: '100%', padding: 20}}>
                <Card>
                    <Card.Body>
                        <Tabs activeKey={tab} onSelect={(tab) => this.setState({tab})} mountOnEnter unmountOnExit>
                            <Tab eventKey="priceTables" title="Tariefstaffels">

                                <div style={{display: 'flex', marginTop: 20}}>
                                    <div style={{width: 300}}>
                                        <ListGroup style={{margin: 20}}>
                                            {priceTables.map((priceTable, index) => {
                                                return (
                                                    <ListGroup.Item
                                                        key={index} action
                                                        onClick={this.onClickSelectPriceTable.bind(this, priceTable)}
                                                    >
                                                        {priceTable.name}
                                                    </ListGroup.Item>)
                                            })}
                                        </ListGroup>

                                        <Button
                                            style={{marginLeft: 20}}
                                            onClick={this.onClickNewPriceTable.bind(this)}
                                        >
                                            Nieuwe Tariefstaffel
                                        </Button>
                                    </div>

                                    <PriceTable priceTable={priceTable}/>
                                </div>
                            </Tab>

                            <Tab eventKey="salaryTables" title="Personeelskostenstaffels">
                                <div style={{display: 'flex', marginTop: 20}}>
                                    <div style={{width: 300}}>
                                        <ListGroup style={{margin: 20}}>
                                            {salaryTables.map((salaryTable, index) => {
                                                return (
                                                    <ListGroup.Item
                                                        key={index} action
                                                        onClick={this.onClickSelectSalaryTable.bind(this, salaryTable)}
                                                    >
                                                        {salaryTable.name}
                                                    </ListGroup.Item>)
                                            })}
                                        </ListGroup>

                                        <Button
                                            style={{marginLeft: 20}}
                                            onClick={this.onClickNewSalaryTable.bind(this)}
                                        >
                                            Nieuwe Personeelskostenstaffel
                                        </Button>
                                    </div>

                                    <SalaryTable salaryTable={salaryTable}/>
                                </div>
                            </Tab>

                            <Tab eventKey="messageSets" title="Berichtensets">
                                <div style={{display: 'flex', marginTop: 20}}>
                                    <div style={{width: 300}}>
                                        <ListGroup style={{margin: 20}}>
                                            {messageSets.map((messageSet, index) => {
                                                return (
                                                    <ListGroup.Item
                                                        key={index} action
                                                        onClick={this.onClickSelectMessageSet.bind(this, messageSet)}
                                                    >
                                                        {messageSet.name}
                                                    </ListGroup.Item>)
                                            })}
                                        </ListGroup>

                                        <Button
                                            style={{marginLeft: 20}}
                                            onClick={this.onClickNewMessageSet.bind(this)}
                                        >
                                            Nieuwe Berichtenset
                                        </Button>
                                    </div>

                                    <MessageSet messageSet={messageSet}/>
                                </div>
                            </Tab>
                        </Tabs>

                        <br/>
                        {message &&
                            <Alert variant='success'>{message}</Alert>
                        }
                        {error &&
                            <Alert variant='danger'>{error}</Alert>
                        }
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

export default Radium(Settings)
