const _ = require('underscore')
const moment = require('moment')

function filter(object, queries) {
    return _.every(queries, (query) => {
        const values = query.text.split(',')

        if (query.key) {
            if (query.operator === '=') {
                return _.some(values, (value) => {
                    value = value.trim().toLowerCase()
                    return traverse(object, query.key).toString().toLowerCase().indexOf(value) > -1
                })
            } else if (query.operator === '!=') {
                return _.every(values, (value) => {
                    value = value.trim().toLowerCase()
                    return traverse(object, query.key).toString().toLowerCase().indexOf(value) === -1
                })
            } else if (query.operator === '<') {
                return _.every(values, (value) => {
                    value = value.trim().toLowerCase()
                    return traverse(object, query.key).toString().toLowerCase() < value
                })
            } else if (query.operator === '>') {
                return _.every(values, (value) => {
                    value = value.trim().toLowerCase()
                    return traverse(object, query.key).toString().toLowerCase() > value
                })
            }
        } else {
            return _.some(values, (value) => {
                value = value.trim().toLowerCase()
                return JSON.stringify(object).toString().toLowerCase().indexOf(value) > -1
            })
        }
    })
}

export default {
    customers: (customers, queries) => {
        return customers.filter((customer) => {
            customer = JSON.parse(JSON.stringify(customer))

            return filter(customer, queries)
        })
    },
    shifts: (shifts, queries) => {
        return shifts.filter((shift) => {
            shift = JSON.parse(JSON.stringify(shift))

            shift.date = moment(shift.date).format('DD-MM-YYYY')
            const hours = parseInt(moment.duration(shift.totalWorked, 'seconds').asHours())
            const minutes = `0${moment.duration(shift.totalWorked, 'seconds').minutes()}`.slice(-2)
            shift.totalWorked = `${hours}:${minutes}`
            shift.performance = `${shift.performance} %`

            return filter(shift, queries)
        })
    },
    users: (users, queries) => {
        return users.filter((user) => {
            user = JSON.parse(JSON.stringify(user))

            return filter(user, queries)
        })
    }
}


/**
* Traverse an object the find the value for the given multilevel key
* @param {object} obj
* @param {string} key multilevel key seperated by dots
* @return {string}
*/
function traverse(obj, key) {
    if (key) {
        return key.split('.').reduce((cur, key) => {
            if (cur && cur[key]) {
                return cur[key]
            } else {
                return ''
            }
        }, obj)
    } else {
        return ''
    }
}
