import Reflux from 'reflux'

export default Reflux.createActions([
    'setStartDate',
    'setEndDate',
    'changeQueries',
    'changeSortKey',
    'setSelected',
    'get',
    'update',
    'remove',
    'endShift',
    'getShiftPriceTable'
])
