import React from 'react'
import Reflux from 'reflux'
import Radium from 'radium'
import moment from 'moment'
import _ from 'underscore'

import ShiftStore from '../stores/ShiftStore'
import ShiftActions from '../actions/ShiftActions'
import UserStore from '../stores/UserStore'

import {Button, Form, Table} from 'react-bootstrap'
import {Colors, SearchBar, Popup} from '../components/UI'
import {DatePicker} from '../components/forms'
import ShiftModal from '../components/shifts/ShiftModal'

class Shifts extends Reflux.Component {
    constructor(props) {
        super(props)

        this.state = {
            columns: [
                {title: 'Datum', key: 'date', render: (shift) => moment(shift.date).format('DD-MM-YYYY')},
                {title: 'Klant', key: 'customer', render: (shift) => shift.customer},
                {title: 'Werktijd', key: 'totalWorked', render: (shift) => {
                    const hours = parseInt(moment.duration(shift.totalWorked, 'seconds').asHours())
                    const minutes = `0${moment.duration(shift.totalWorked, 'seconds').minutes()}`.slice(-2)
                    return <div style={{color: shift.totalWorked >= 3600*24 ? Colors.danger: ''}}>{`${hours}:${minutes}`}</div>
                }},
                {title: 'Wachttijd', key: 'totalWait', render: (shift) => {
                    const hours = parseInt(moment.duration(shift.totalWait, 'seconds').asHours())
                    const minutes = `0${moment.duration(shift.totalWait, 'seconds').minutes()}`.slice(-2)
                    return `${hours}:${minutes}`
                }},
                {title: 'Pauzetijd', key: 'totalBreak', render: (shift) => {
                    const hours = parseInt(moment.duration(shift.totalBreak, 'seconds').asHours())
                    const minutes = `0${moment.duration(shift.totalBreak, 'seconds').minutes()}`.slice(-2)
                    return `${hours}:${minutes}`
                }},
                {title: 'Bezorgadres', key: 'nrDelivered', render: (shift) => shift.nrDelivered},
                {title: 'Niet thuis', key: 'nrNotDelivered', render: (shift) => shift.nrNotDelivered},
                {title: 'Koerier', key: 'user', render: (shift) => shift.user},
                {title: 'Performance', key: 'performance', render: (shift) => {
                    let color = Colors.success

                    if (shift.performance < 100) {
                        color = Colors.warning
                    }

                    if (shift.performance < 80) {
                        color = Colors.danger
                    }

                    return (
                        shift.endTime ?
                            <div style={{fontSize: '110%', color}}>{`${shift.performance} %`}</div> :
                            <div/>
                    )
                }},
                {title: 'Feedback', key: 'feedback', render: (shift) => shift.feedback}
            ]
        }

        this.stores = [ShiftStore, UserStore]
    }

    componentDidMount() {
        ShiftActions.get(true)
    }

    onClickRemove() {
        const {selectedShifts} = this.state

        if (selectedShifts.length === 1) {
            this.popup.open('Dienst verwijderen', 'Weet je dit zeker?', () => {
                ShiftActions.remove(selectedShifts[0], (err) => {
                    if (!err) {
                        ShiftActions.setSelected([])
                        this.popup.close()
                    } else {
                        this.popup.setError(err)
                    }
                })
            })
        }
    }

    onClickSelectAll() {
        const {filteredShifts, selectedShifts} = this.state

        if (filteredShifts.length === selectedShifts.length) {
            ShiftActions.setSelected([])
        } else {
            ShiftActions.setSelected(_.pluck(filteredShifts, '_id'))
        }
    }

    onClickSelect(id) {
        const selectedShifts = JSON.parse(JSON.stringify(this.state.selectedShifts))
        const index = selectedShifts.indexOf(id)

        if (index === -1) {
            selectedShifts.push(id)
        } else {
            selectedShifts.splice(index, 1)
        }

        ShiftActions.setSelected(selectedShifts)
    }

    onClickView(shift) {
        this.shiftModal.open(shift)
        ShiftActions.setSelected([])
    }

    onClickEndShift(id) {
        this.popup.open('Dienst stoppen', 'Weet je dit zeker?', () => {
            ShiftActions.endShift(id, (err) => {
                if (!err) {
                    this.popup.close()
                } else {
                    this.popup.setError(err)
                }
            })
        })
    }

    render() {
        const {filteredShifts, selectedShifts, startDate, endDate, shiftQueries, shiftsSortKey, shiftsSortReverse, columns, users} = this.state

        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%'}}>
                <div style={{display: 'flex', width: '100%', padding: 20}}>
                    <DatePicker
                        style={{width: 190, marginRight: 10}}
                        value={startDate}
                        onChange={(event) => ShiftActions.setStartDate(event.target.value)}
                    />
                    <DatePicker
                        style={{width: 190, marginRight: 10}}
                        value={endDate}
                        onChange={(event) => ShiftActions.setEndDate(event.target.value)}
                    />

                    <SearchBar
                        columns={columns}
                        queries={shiftQueries || []}
                        onChange={ShiftActions.changeQueries}
                    />

                    <div style={{display: 'flex', height: 38}}>
                        {selectedShifts.length === 1 &&
                            <Button
                                style={{marginLeft: 10}}
                                variant='danger'
                                onClick={this.onClickRemove.bind(this)}
                            >Verwijder dienst</Button>
                        }
                    </div>
                </div>

                <div style={{background: Colors.light, width: '100%', height: '100%', overflowY: 'scroll'}}>
                    <Table responsive >
                        <thead>
                            <tr>
                                <th>
                                    <Form.Check
                                        checked={filteredShifts.length && selectedShifts.length === filteredShifts.length}
                                        onChange={this.onClickSelectAll.bind(this)}
                                    />
                                </th>
                                {columns.map((column) => {
                                    return (
                                        <th
                                            key={column.key}
                                            style={{cursor: 'pointer', userSelect: 'none'}}
                                            onClick={() => ShiftActions.changeSortKey(column.key)}
                                        >
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <div>{column.title}</div>
                                                {column.key === shiftsSortKey && shiftsSortReverse &&
                                                    <i className='mdi mdi-sort-descending'/>
                                                }
                                                {column.key === shiftsSortKey && !shiftsSortReverse &&
                                                    <i className='mdi mdi-sort-ascending'/>
                                                }
                                            </div>
                                        </th>
                                    )
                                })}
                                <th/>
                            </tr>
                        </thead>

                        <tbody>
                            {filteredShifts.map((shift, index) => {
                                return (
                                    <tr key={shift._id}>
                                        <td>
                                            <Form.Check
                                                checked={selectedShifts.indexOf(shift._id) > -1}
                                                onChange={this.onClickSelect.bind(this, shift._id)}
                                            />
                                        </td>
                                        {columns.map((column) => {
                                            return <td key={column.key}>{column.render(shift)}</td>
                                        })}
                                        <td>
                                            {shift.endTime ?
                                                <Button size='sm' onClick={this.onClickView.bind(this, shift)}>
                                                    <i className='mdi mdi-eye'/>
                                                </Button> :
                                                <Button size='sm' variant='danger' onClick={this.onClickEndShift.bind(this, shift._id)}>
                                                    <i className='mdi mdi-stop'/>
                                                </Button>
                                            }
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>

                <ShiftModal ref={(ref) => this.shiftModal = ref} users={users}/>
                <Popup ref={(ref) => this.popup = ref}/>
            </div>
        )
    }
}

export default Radium(Shifts)
