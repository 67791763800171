import React from 'react'
import Radium from 'radium'
import _ from 'underscore'
import Colors from './Colors.js'


class Divider extends React.Component {
    render() {
        const {style} = this.props

        const defaultStyle = {
            width: '100%',
            height: 1,
            backgroundColor: Colors.grey

        }

        _.keys(style).map((key) => {
            defaultStyle[key] = style[key]
        })

        return (
            <div style={defaultStyle}/>
        )
    }
}

export default Radium(Divider)
