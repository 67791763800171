import React from 'react'
import moment from 'moment'
import Radium from 'radium'
import {Button, Form, InputGroup, Overlay, Popover} from 'react-bootstrap'
import {IconButton, Colors} from '../UI'


const monthLabels=['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december']
const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
const dayLabels = ['Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za', 'Zo']

class DatePicker extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            target: null,
            show: false,
            inputFocused: false,
            timeout: null,
            value: moment(props.value).format('DD-MM-YYYY'),
            displayDate: props.value
        }
    }

    componentDidUpdate(prevProps) {
        const {value} = this.props

        if (prevProps.value !== value) {
            this.setState({value: moment(value).format('DD-MM-YYYY'), displayDate: value})
        }
    }

    componentWillUnmount() {
        clearTimeout(this.state.timeout)
    }

    handleFocus(event) {
        this.setState({
            target: this.formControl,
            show: true,
            inputFocused: true
        })
    }

    handleBlur(event) {
        this.setState({
            inputFocused: false,
            timeout: setTimeout(() => {
                const {value} = this.props

                if (!this.state.inputFocused) {
                    this.setState({value: moment(value).format('DD-MM-YYYY'), target: null, show: false})
                }
            }, 100)
        })
    }

    handleChangeInput(event) {
        const {onChange} = this.props

        let value = event.target.value.replace('.', '-').replace('/', '-')

        if (this.state.value.length === 1 && value.length === 2) {
            value = `${value}-`
        }

        if (this.state.value.length === 4 && value.length === 5) {
            value = `${value}-`
        }

        if (!/^[0-9]{1,4}[-]{2}[0-9]{1,4}$/.test(value)) {
            value = value.replace('--', '-')
        }

        if (/^$|^[0-9]{0,2}[-]?[0-9]{0,2}[-]?[0-9]{0,4}$/.test(value)) {
            this.setState({value})

            if (/^[0-9]{2}-[0-9]{2}-[0-9]{4}$/.test(value) && moment(value, 'DD-MM-YYYY').isValid()) {
                event.target.value = moment(value, 'DD-MM-YYYY').format('YYYY-MM-DD')
                onChange(event)
            }
        }
    }

    handleClickPrevious() {
        const {displayDate} = this.state

        const newDate = moment(displayDate).subtract(1, 'months').format('YYYY-MM-DD')

        this.setState({displayDate: newDate})
    }

    handleClickNext() {
        const {displayDate} = this.state

        const newDate = moment(displayDate).add(1, 'months').format('YYYY-MM-DD')

        this.setState({displayDate: newDate})
    }

    handleClickDate(date, event) {
        const {onChange} = this.props

        event.target.value = date

        this.formControl.blur()
        onChange(event)
    }

    handleClickToday(event) {
        const {onChange} = this.props

        event.target.value = moment().format('YYYY-MM-DD')

        this.formControl.blur()
        onChange(event)
    }

    onMouseDownPopover() {
        setTimeout(() => {
            this.formControl.focus()
        }, 20)
    }

    render() {
        const {displayDate, target, show} = this.state
        const {value, size, disabled, style, startDate, endDate, weekDaysOnly, showTodayButton=true} = this.props

        const year = moment(displayDate).year()
        const month = moment(displayDate).month()
        const firstDay = moment(displayDate).startOf('month').day()
        const startingDay = firstDay === 0 ? 6 : firstDay - 1

        let monthLength = daysInMonth[month]
        if (month == 1) {
            if ((year % 4 == 0 && year % 100 != 0) || year % 400 == 0) {
                monthLength = 29
            }
        }

        const weeks = []
        let day = 1

        for (let i = 0; i <= 5; i++) {
            const week = []

            for (let j = 0; j <= 6; j++) {
                if (day <= monthLength && (i > 0 || j >= startingDay)) {
                    week.push(day)
                    day++
                } else {
                    week.push(null)
                }
            }

            weeks.push(week)

            if (day > monthLength) {
                break
            }
        }

        const calendar = (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <table>
                    <thead>
                        <tr>
                            {dayLabels.map((label) => {
                                return (
                                    <td
                                        key={label}
                                        style={{textAlign: 'center', width: 30, height: 30, fontSize: '0.8em', color: 'grey'}}
                                    >
                                        {label}
                                    </td>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {weeks.map((week, index) => {
                            return (
                                <tr key={`tr-${index}`}>
                                    {week.map((day, index) => {
                                        if (day) {
                                            const date = moment().year(year).month(month).date(day).format('YYYY-MM-DD')

                                            let disabled = false
                                            if (startDate && date < startDate) {
                                                disabled = true
                                            }
                                            if (endDate && date > endDate) {
                                                disabled = true
                                            }

                                            if (weekDaysOnly && (moment(date).day() === 6 || moment(date).day() === 0)) {
                                                disabled = true
                                            }

                                            return (
                                                <td
                                                    key={`td-${date}`}
                                                    onClick={disabled ? () => {} : this.handleClickDate.bind(this, date)}
                                                    style={{
                                                        textAlign: 'center',
                                                        cursor: disabled ? 'default': 'pointer',
                                                        borderRadius: '50%',
                                                        width: 30,
                                                        height: 30,
                                                        background: date === value ? Colors.primary : 'transparent',
                                                        color: date === value ? 'white' : disabled ? 'grey' : 'black',
                                                        ':hover': {background: date === value ? Colors.primary : disabled ? 'white' : Colors.lightGrey}
                                                    }}
                                                >
                                                    {day}
                                                </td>
                                            )
                                        } else {
                                            return <td key={`tdnull-${index}`}/>
                                        }
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                {showTodayButton &&
                    <Button style={{width: '100%', marginTop: 5}} onClick={this.handleClickToday.bind(this)}>Vandaag</Button>
                }
            </div>
        )

        return (
            <div style={style}>
                <InputGroup size={size}>
                    <Form.Control
                        pattern='[0-9]{2}-[0-9]{2}-[0-9]{4}'
                        title='DD-MM-JJJJ'
                        disabled={disabled}
                        value={disabled ? '' : this.state.value}
                        onFocus={disabled ? () => {} : this.handleFocus.bind(this)}
                        onBlur={this.handleBlur.bind(this)}
                        onChange={this.handleChangeInput.bind(this)}
                        ref={(ref) => this.formControl = ref}
                    />

                    <InputGroup.Append>
                        <InputGroup.Text>
                            <i style={{fontSize: '0.9em'}} className='mdi mdi-calendar'/>
                        </InputGroup.Text>
                    </InputGroup.Append>
                </InputGroup>

                <Overlay
                    show={show}
                    target={target}
                    placement='auto'
                >
                    <Popover onMouseDown={this.onMouseDownPopover.bind(this)}>
                        <Popover.Title>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
                                <IconButton onClick={this.handleClickPrevious.bind(this)} >
                                    {'<'}
                                </IconButton>
                                <div style={{flex: 1, textAlign: 'center'}}>
                                    <span>{monthLabels[month]} {year}</span>
                                </div>

                                <IconButton onClick={this.handleClickNext.bind(this)} >
                                    {'>'}
                                </IconButton>
                            </div>
                        </Popover.Title>
                        <Popover.Content>
                            {calendar}
                        </Popover.Content>
                    </Popover>
                </Overlay>
            </div>
        )
    }
}

export default Radium(DatePicker)
