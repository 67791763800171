import React from 'react'
import Reflux from 'reflux'
import Radium from 'radium'
import FileSaver from 'file-saver'
import encoding from 'encoding'

import CustomerStore from '../stores/CustomerStore'
import UserStore from '../stores/UserStore'
import ResultStore from '../stores/ResultStore'
import ResultActions from '../actions/ResultActions'

import {Alert, Button, Card, Form, Row, Col} from 'react-bootstrap'
import Select from 'react-select'

import {DatePicker} from '../components/forms'
import {ChartCard} from '../components/UI'

class Dashboard extends Reflux.Component {
    constructor(props) {
        super(props)

        this.state = {
            error: ''
        }

        this.stores = [CustomerStore, UserStore, ResultStore]
    }

    componentDidMount() {
        this.onClickRefreshData()
    }

    onClickRefreshData() {
        ResultActions.getStatistics((err, statistics) => {
            if (err) {
                this.setState({error: err})
            } else {
                this.setState({statistics})
            }
        })
    }

    onClickGetCustomerSpecificationCSV(type, event) {
        const {customerName, startDate, endDate} = this.state

        ResultActions.getCustomerSpecificationCSV(type, (err, csv) => {
            if (err) {
                this.setState({error: err})
            } else {
                const blob = new Blob([encoding.convert(csv, 'windows-1252')], {type: 'data:text/csv;charset=windows-1252'})
                FileSaver.saveAs(blob, `Rapportage ${customerName} ${startDate}-${endDate}.csv`)
            }
        })
    }

    onClickGetCustomerSpecification2CSV() {
        const {customerName, startDate, endDate} = this.state

        ResultActions.getCustomerSpecification2CSV((err, csv) => {
            if (err) {
                this.setState({error: err})
            } else {
                const blob = new Blob([encoding.convert(csv, 'windows-1252')], {type: 'data:text/csv;charset=windows-1252'})
                FileSaver.saveAs(blob, `Rapportage ${customerName} ${startDate}-${endDate}.csv`)
            }
        })
    }

    onClickGetCustomerResultCSV() {
        const {customerName, startDate, endDate} = this.state

        ResultActions.getCustomerResultCSV((err, csv) => {
            if (err) {
                this.setState({error: err})
            } else {
                const blob = new Blob([encoding.convert(csv, 'windows-1252')], {type: 'data:text/csv;charset=windows-1252'})
                FileSaver.saveAs(blob, `Resultaten ${customerName} ${startDate}-${endDate}.csv`)
            }
        })
    }

    onClickGetCustomerInvoiceCSV() {
        const {customerName, startDate, endDate} = this.state

        ResultActions.getCustomerInvoiceCSV((err, csv) => {
            if (err) {
                this.setState({error: err})
            } else {
                const blob = new Blob([encoding.convert(csv, 'windows-1252')], {type: 'data:text/csv;charset=windows-1252'})
                FileSaver.saveAs(blob, `Specificatie ${customerName} ${startDate}-${endDate}.csv`)
            }
        })
    }

    onClickGetCustomerInvoicePDF() {
        const {customerName, startDate, endDate} = this.state

        ResultActions.getCustomerInvoicePDF((err, pdf) => {
            if (err) {
                this.setState({error: err})
            } else {
                const raw = window.atob(pdf)
                const rawLength = raw.length
                const array = new Uint8Array(new ArrayBuffer(rawLength))

                for (let i = 0; i < rawLength; i++) {
                    array[i] = raw.charCodeAt(i)
                }

                const blob = new Blob([array], {type: 'application/pdf'})
                FileSaver.saveAs(blob, `Specificatie ${customerName} ${startDate}-${endDate}.pdf`)
            }
        })
    }

    onClickGetUserResultCSV() {
        const {userName, startDate, endDate} = this.state

        ResultActions.getUserResultCSV((err, csv) => {
            if (err) {
                this.setState({error: err})
            } else {
                const blob = new Blob([encoding.convert(csv, 'windows-1252')], {type: 'data:text/csv;charset=windows-1252'})
                FileSaver.saveAs(blob, `Resultaat ${userName} ${startDate}-${endDate}.csv`)
            }
        })
    }

    onClickGetUserSalaryCSV() {
        const {userName, startDate, endDate} = this.state

        ResultActions.getUserSalaryCSV((err, csv) => {
            if (err) {
                this.setState({error: err})
            } else {
                const blob = new Blob([encoding.convert(csv, 'windows-1252')], {type: 'data:text/csv;charset=windows-1252'})
                FileSaver.saveAs(blob, `Loon ${userName} ${startDate}-${endDate}.csv`)
            }
        })
    }

    onClickGetRevenueCSV() {
        const {startDate, endDate} = this.state

        ResultActions.getRevenueCSV((err, csv) => {
            if (err) {
                this.setState({error: err})
            } else {
                const blob = new Blob([encoding.convert(csv, 'windows-1252')], {type: 'data:text/csv;charset=windows-1252'})
                FileSaver.saveAs(blob, `Omzet ${startDate}-${endDate}.csv`)
            }
        })
    }

    render() {
        const {customers, customerName, startDate, endDate, users, userName, statistics, error} = this.state
        const {user} = this.props

        if (!user || user.type !== 'Admin') {
            return <div/>
        }

        return (
            <div style={{height: '100%', width: '100%'}}>
                {error &&
                    <Alert variant='danger' dismissible onClose={() => this.setState({error: ''})}>{error}</Alert>
                }

                <div style={{display: 'flex', height: '100%', padding: 50}}>
                    <div>
                        <Card style={{width: 650, margin: 20}}>
                            <Card.Header>
                                <Card.Title>Klanten</Card.Title>
                            </Card.Header>
                            <Card.Body>

                                <Form.Group>
                                    <Select
                                        placeholder='Selecteer klant...'
                                        options={customers.map((customer) => {
                                            return {value: customer.name, label: customer.name}
                                        })}
                                        value={customerName ? {value: customerName, label: customerName} : null}

                                        onChange={(customer) => ResultActions.setCustomer(customer.value)}
                                    />
                                </Form.Group>

                                <Row>
                                    <Col>
                                        <DatePicker
                                            value={startDate}
                                            onChange={(event) => ResultActions.setStartDate(event.target.value)}
                                        />
                                    </Col>
                                    <Col>
                                        <DatePicker
                                            value={endDate}
                                            onChange={(event) => ResultActions.setEndDate(event.target.value)}
                                        />
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer>

                                <Button
                                    disabled={!customerName}
                                    style={{marginRight: 10, marginBottom: 16}}
                                    onClick={this.onClickGetCustomerSpecificationCSV.bind(this)}
                                >
                                    Rapportage .csv
                                </Button>

                                <Button
                                    disabled={!customerName}
                                    style={{marginRight: 10, marginBottom: 16}}
                                    onClick={this.onClickGetCustomerSpecificationCSV.bind(this, 'data')}
                                >
                                    Dataexport .csv
                                </Button>

                                <Button
                                    disabled={!customerName}
                                    style={{marginRight: 10, marginBottom: 16}}
                                    onClick={this.onClickGetCustomerResultCSV.bind(this)}
                                >
                                    Resultaat .csv
                                </Button>

                                <Button
                                    disabled={!customerName}
                                    style={{marginRight: 10, marginBottom: 16}}
                                    onClick={this.onClickGetCustomerInvoiceCSV.bind(this)}
                                >
                                    Specificatie .csv
                                </Button>

                                <Button
                                    disabled={!customerName}
                                    style={{marginRight: 10, marginBottom: 16}}
                                    onClick={this.onClickGetCustomerInvoicePDF.bind(this)}
                                >
                                    Specificatie .pdf
                                </Button>
                            </Card.Footer>
                        </Card>

                        <Card style={{width: 475, margin: 20}}>
                            <Card.Header>
                                <Card.Title>Medewerkers</Card.Title>
                            </Card.Header>
                            <Card.Body>

                                <Form.Group>
                                    <Select
                                        placeholder='Selecteer medewerker...'
                                        options={[
                                            {value: 'all', label: 'Alle medewerkers'},
                                            {value: 'zzp', label: 'Alle medewerkers zzp'},
                                            {value: 'contract', label: 'Alle medewerkers in loondienst'},
                                            ...users.map((user) => {
                                                return {value: user.name, label: user.name}
                                            })
                                        ]}
                                        value={userName ? {value: userName, label: userName} : null}

                                        onChange={(user) => ResultActions.setUser(user.value)}
                                    />
                                </Form.Group>

                                <Row>
                                    <Col>
                                        <DatePicker
                                            value={startDate}
                                            onChange={(event) => ResultActions.setStartDate(event.target.value)}
                                        />
                                    </Col>
                                    <Col>
                                        <DatePicker
                                            value={endDate}
                                            onChange={(event) => ResultActions.setEndDate(event.target.value)}
                                        />
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer>
                                <Button
                                    disabled={!userName || userName === 'all' || userName === 'zzp' || userName === 'contract'}
                                    style={{marginRight: 10, marginBottom: 16}}
                                    onClick={this.onClickGetUserResultCSV.bind(this)}
                                >
                                    Resultaat .csv
                                </Button>
                                <Button
                                    disabled={!userName}
                                    style={{marginRight: 10, marginBottom: 16}}
                                    onClick={this.onClickGetUserSalaryCSV.bind(this)}
                                >
                                    Salaris .csv
                                </Button>
                            </Card.Footer>
                        </Card>
                    </div>

                    <div style={{margin: 20}}>


                        <ChartCard
                            title='Omzet & kosten'
                            data={statistics}
                            footer={
                                <div style={{display: 'flex'}}>
                                    <DatePicker
                                        style={{width: 190, marginRight: 10}}
                                        value={startDate}
                                        onChange={(event) => ResultActions.setStartDate(event.target.value)}
                                    />
                                    <DatePicker
                                        style={{width: 190, marginRight: 10}}
                                        value={endDate}
                                        onChange={(event) => ResultActions.setEndDate(event.target.value)}
                                    />
                                    <Button
                                        onClick={this.onClickRefreshData.bind(this)}
                                    >
                                        <i className='mdi mdi-refresh'/>
                                    </Button>

                                    <Button
                                        style={{marginLeft: 10}}
                                        onClick={this.onClickGetRevenueCSV.bind(this)}
                                    >
                                        Omzet export .csv
                                    </Button>
                                </div>
                            }
                        />
                    </div>
                </div>
            </div>
        )
    }
}


export default Radium(Dashboard)
