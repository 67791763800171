import Reflux from 'reflux'
import socket from '../../socketio/socketio.js'

import SettingsActions from '../actions/SettingsActions.js'

export default class SettingsStore extends Reflux.Store {
    constructor() {
        super()

        this.state = {
            priceTables: [],
            priceTable: null,
            salaryTables: [],
            salaryTable: null,
            messageSets: [],
            messageSet: null
        }

        this.listenables = SettingsActions

        SettingsActions.getPriceTables()
        SettingsActions.getSalaryTables()
        SettingsActions.getMessageSets()

        socket.on('updatePriceTables', () => {
            SettingsActions.getPriceTables()
        })

        socket.on('updateSalaryTables', () => {
            SettingsActions.getSalaryTables()
        })

        socket.on('updateMessageSets', () => {
            SettingsActions.getMessageSets()
        })
    }

    onGetPriceTables() {
        socket.emit('settings.getPriceTables', sessionStorage.token, (err, priceTables) => {
            if (!err) {
                this.setState({priceTables})
            }
        })
    }

    onUpdatePriceTable(priceTable, callback) {
        socket.emit('settings.updatePriceTable', sessionStorage.token, priceTable, callback)
    }

    onRemovePriceTable(id, callback) {
        socket.emit('settings.removePriceTable', sessionStorage.token, id, (err) => {
            if (!err) {
                this.setState({priceTable: null})
            }
            typeof callback === 'function' && callback(err)
        })
    }

    onSelectPriceTable(priceTable) {
        this.setState({priceTable})
    }


    onGetSalaryTables() {
        socket.emit('settings.getSalaryTables', sessionStorage.token, (err, salaryTables) => {
            if (!err) {
                this.setState({salaryTables})
            }
        })
    }

    onUpdateSalaryTable(salaryTable, callback) {
        socket.emit('settings.updateSalaryTable', sessionStorage.token, salaryTable, callback)
    }

    onRemoveSalaryTable(id, callback) {
        socket.emit('settings.removeSalaryTable', sessionStorage.token, id, (err) => {
            if (!err) {
                this.setState({salaryTable: null})
            }
            typeof callback === 'function' && callback(err)
        })
    }

    onSelectSalaryTable(salaryTable) {
        this.setState({salaryTable})
    }


    onGetMessageSets() {
        socket.emit('settings.getMessageSets', sessionStorage.token, (err, messageSets) => {
            if (!err) {
                this.setState({messageSets})
            }
        })
    }

    onUpdateMessageSet(messageSet, callback) {
        socket.emit('settings.updateMessageSet', sessionStorage.token, messageSet, callback)
    }

    onRemoveMessageSet(id, callback) {
        socket.emit('settings.removeMessageSet', sessionStorage.token, id, (err) => {
            if (!err) {
                this.setState({messageSet: null})
            }
            typeof callback === 'function' && callback(err)
        })
    }

    onSelectMessageSet(messageSet) {
        this.setState({messageSet})
    }
}
