import React from 'react'
import Radium from 'radium'

import SettingsActions from '../../actions/SettingsActions.js'

import {Alert, Button, Card, Form} from 'react-bootstrap'
import {Price} from '../forms'
import {IconButton, Popup} from '../UI'


class SalaryTable extends React.Component {
    constructor(props) {
        super(props)

        this.state ={
            salaryTable: JSON.parse(JSON.stringify((props.salaryTable))),
            edit: false,
            message: '',
            error: ''
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.salaryTable) !== JSON.stringify(prevProps.salaryTable)) {
            this.setState(({salaryTable: JSON.parse(JSON.stringify(this.props.salaryTable)), message: '', error: ''}))
        }
    }

    onChangeName(event) {
        const salaryTable = JSON.parse(JSON.stringify(this.state.salaryTable))
        salaryTable.name = event.target.value
        this.setState({salaryTable})
    }

    onClickAddYear() {
        const {salaryTable} = this.state
        salaryTable.years.push(0)
        this.setState({salaryTable})
    }

    onClickRemoveYear(year) {
        const {salaryTable} = this.state
        salaryTable.years.splice(year, 1)
        this.setState({salaryTable})
    }

    onChangeSalary(key, index, event) {
        const {salaryTable} = this.state
        salaryTable[key][index] = parseFloat(event.target.value)
        this.setState({salaryTable})
    }

    onChangeZZPSalary(event) {
        const {salaryTable} = this.state
        salaryTable.zzpSalary = parseFloat(event.target.value)
        this.setState({salaryTable})
    }

    onClickSave() {
        const {salaryTable} = this.state

        this.setState({message: '', error: ''})

        SettingsActions.updateSalaryTable(salaryTable, (err) => {
            if (err) {
                this.setState({error: err})
            } else {
                this.setState({edit: false, message: 'Wijzigingen opgeslagen'})
            }
        })
    }

    onClickRemove() {
        const {salaryTable} = this.state

        this.popup.open('Verwijder Loontabel', '', () => {
            SettingsActions.removeSalaryTable(salaryTable._id, (err) => {
                if (err) {
                    this.popup.setError(err)
                }
            })
        })
    }

    render() {
        const {salaryTable, edit, message, error} = this.state

        if (!salaryTable) {
            return null
        }

        return (
            <Card style={{marginTop: 20}}>
                <Card.Header>
                    <Card.Title>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            {edit ?
                                <Form.Control style={{width: 300}} value={salaryTable.name} onChange={this.onChangeName.bind(this)}/> :
                                <h5>{salaryTable.name}</h5>
                            }
                        </div>
                    </Card.Title>
                </Card.Header>

                <Card.Body>
                    <div style={{display: 'flex'}}>
                        <h6 style={{width: 100}}>Leeftijd</h6>
                        <h6 style={{width: 185, marginLeft: 20}}>Loon</h6>
                    </div>


                    {salaryTable.age.map((salary, index) => {
                        const age = index + 17

                        return (
                            <div key={`age${age}`} style={{display: 'flex'}}>

                                <p style={{width: 100}}>{age}</p>

                                <div style={{width: 125, marginLeft: 20}}>
                                    {edit ?
                                        <Price
                                            value={salary || 0}
                                            onChange={this.onChangeSalary.bind(this, 'age', index)}
                                        /> :
                                        <p>{`€ ${(salary || 0).toFixed(2).replace('.', ',')}`}</p>
                                    }
                                </div>
                            </div>
                        )
                    })}
                    <br/>

                    <div style={{display: 'flex'}}>
                        <h6 style={{width: 100}}>Dienstjaren</h6>
                        <h6 style={{width: 185, marginLeft: 20}}>Loon</h6>
                    </div>


                    {salaryTable.years.map((salary, year) => {
                        return (
                            <div key={`years${year}`} style={{display: 'flex'}}>

                                <p style={{width: 100}}>{year}</p>

                                <div style={{width: 125, marginLeft: 20}}>
                                    {edit ?
                                        <Price
                                            value={salary || 0}
                                            onChange={this.onChangeSalary.bind(this, 'years', year)}
                                        /> :
                                        <p>{`€ ${(salary || 0).toFixed(2).replace('.', ',')}`}</p>
                                    }
                                </div>

                                <div style={{width: 60}}>
                                    {edit &&
                                        <IconButton onClick={this.onClickRemoveYear.bind(this, year)}>
                                            <i className='mdi mdi-delete'/>
                                        </IconButton>
                                    }
                                </div>
                            </div>
                        )
                    })}


                    {edit &&
                        <IconButton onClick={this.onClickAddYear.bind(this)}>
                            <i className='mdi mdi-plus'/>
                        </IconButton>
                    }

                    <br/>
                    <div style={{display: 'flex'}}>
                        <h6 style={{width: 100}}>ZZP loon</h6>
                    </div>

                    {edit ?
                        <Price
                            style={{width: '50%'}}
                            value={salaryTable.zzpSalary || 0}
                            onChange={this.onChangeZZPSalary.bind(this)}
                        /> :
                        <p>{`€ ${(salaryTable.zzpSalary || 0).toFixed(2).replace('.', ',')}`}</p>
                    }

                    {message &&
                        <Alert variant='success' dismissible onClose={() => this.setState({message: ''})}>{message}</Alert>
                    }
                    {error &&
                        <Alert variant='danger' dismissible onClose={() => this.setState({error: ''})}>{error}</Alert>
                    }
                </Card.Body>

                <Card.Footer>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        {edit ?
                            <Button onClick={this.onClickSave.bind(this)}>Opslaan</Button> :
                            <Button onClick={() => this.setState({edit: true, message: ''})}>Wijzigen</Button>
                        }
                        <Button variant='danger' onClick={this.onClickRemove.bind(this)} >
                            Verwijder
                        </Button>
                    </div>
                </Card.Footer>

                <Popup ref={(ref) => this.popup = ref}/>
            </Card>
        )
    }
}

export default Radium(SalaryTable)
