module.exports = () => {
    return JSON.parse(JSON.stringify(
        {
            name: '',
            address: {
                name: '',
                street: '',
                nr: '',
                addition: '',
                street2: '',
                postalCode: '',
                city: '',
                country: 'NL'
            },
            email: '',
            phone: '',
            priceTable: '',
            messageSet: '',
            extraPharmacy: false,
            minimumPerDay: false,
            callOutFee: false,
            invoiceHours: false,
            distanceFee: false
        }
    ))
}
