import React from 'react'
import Radium from 'radium'
import _ from 'underscore'
import Colors from './Colors.js'


class IconButton extends React.Component {
    render() {
        const {children, style, color, disabled, onClick} = this.props

        const defaultStyle = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 35,
            height: 35,
            textAlign: 'center',
            textDecoration: 'none',
            fontSize: 18,
            background: 'none',
            color: disabled ? 'grey' : color || Colors.primary,
            border: 'none',
            borderRadius: '50%',
            outline: 0,
            cursor: disabled ? 'not-allowed' : 'pointer',
            transition: '0.1s all',
            ':hover': {
                background: Colors.lightGrey
            }
        }

        _.keys(style).map((key) => {
            defaultStyle[key] = style[key]
        })

        return (
            <button style={defaultStyle} onClick={disabled ? null : onClick}>
                {children}
            </button>
        )
    }
}

export default Radium(IconButton)
