import React from 'react'
import Radium from 'radium'
import {Form, Row, Col} from 'react-bootstrap'

class Address extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            validation: {}
        }
    }

    setValue(key, event) {
        const {validation} = this.state
        validation[key] = null
        this.setState({validation})

        const {address, onChange} = this.props
        address[key] = event.target.value
        onChange(address)
    }

    setPostalCode(event) {
        const {validation} = this.state
        validation.postalCode = null
        this.setState({validation})

        const {address, onChange} = this.props
        let postalCode = event.target.value.toUpperCase()
        if (/^[0-9]{4}[A-Z]{2}$/.test(postalCode)) {
            postalCode = postalCode.substr(0, 4) + ' ' + postalCode.substr(4, 2)
        }
        address.postalCode = postalCode
        onChange(address)
    }

    setAddition(event) {
        const {address, onChange} = this.props
        address.addition = event.target.value.toUpperCase()

        if (/[0-9]/.test(address.addition[0])) {
            address.addition = `-${address.addition}`
        } else if (address.addition[1] && /[-]/.test(address.addition[0]) && /[^0-9]/.test(address.addition[1])) {
            address.addition = address.addition.substr(1)
        }

        onChange(address)
    }

    validate() {
        const {address, id = ''} = this.props
        const validation = {}
        let valid = true
        let focusId = ''

        if (!address.city) {
            focusId = `city${id}`
            validation.city = true
            valid = false
        }

        if (!address.street) {
            focusId = `street${id}`
            validation.street = true
            valid = false
        }

        if (address.country === 'NL' && !address.nr) {
            focusId = `nr${id}`
            validation.nr = true
            valid = false
        }

        if (!address.postalCode || (address.country === 'NL' && !/^[0-9]{4} [A-Z]{2}$/.test(address.postalCode))) {
            focusId = `postalCode${id}`
            validation.postalCode = true
            valid = false
        }

        if (!address.name) {
            focusId = `name${id}`
            validation.name = true
            valid = false
        }

        if (!valid) {
            document.getElementById(focusId).focus()
        }

        this.setState({validation})
        return valid
    }

    render() {
        const {validation} = this.state
        const {id='', address} = this.props

        return (
            <div>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Naam</Form.Label>
                            <Form.Control
                                size="sm"
                                id={`name${id}`}
                                value={address.name || ''}
                                isInvalid={validation.name}
                                onChange={this.setValue.bind(this, 'name')}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Postcode</Form.Label>
                            <Form.Control
                                size="sm"
                                id={`postalCode${id}`}
                                value={address.postalCode || ''}
                                isInvalid={validation.postalCode}
                                onChange={this.setPostalCode.bind(this)}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Huisnummer</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        id={`nr${id}`}
                                        value={address.nr || ''}
                                        isInvalid={validation.nr}
                                        onChange={this.setValue.bind(this, 'nr')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Toevoeging</Form.Label>
                                    <Form.Control
                                        size="sm"
                                        value={address.addition || ''}
                                        onChange={this.setAddition.bind(this)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Straat</Form.Label>
                            <Form.Control
                                size="sm"
                                id={`street${id}`}
                                value={address.street || ''}
                                isInvalid={validation.street}
                                onChange={this.setValue.bind(this, 'street')}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>2e adres regel</Form.Label>
                            <Form.Control
                                size="sm"
                                value={address.street2 || ''}
                                onChange={this.setValue.bind(this, 'street2')}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Plaats</Form.Label>
                            <Form.Control
                                size="sm"
                                id={`city${id}`}
                                value={address.city || ''}
                                isInvalid={validation.city}
                                onChange={this.setValue.bind(this, 'city')}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Land</Form.Label>
                            <Form.Control
                                size="sm"
                                as="select"
                                value={address.country || ''}
                                onChange={this.setValue.bind(this, 'country')}
                            >
                                <option value='NL'>Nederland</option>
                                <option value='BE'>België</option>
                                <option value='DE'>Duitsland</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Radium(Address)
