module.exports = () => {
    return JSON.parse(JSON.stringify(
        {
            name: 'Nieuwe berichtenset',
            income: 0,
            message1: '',
            message3: '',
            message2: ''
        }
    ))
}
