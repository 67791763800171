import React from 'react'
import Radium from 'radium'
import _ from 'underscore'

import UserActions from '../../actions/UserActions.js'

import {Alert, Button, Modal, Form, Row, Col} from 'react-bootstrap'
import {DatePicker} from '../forms'
import Select from 'react-select'
import Spinner from 'react-spinkit'

import NewUser from './NewUser.js'


class UserModal extends React.Component {
    constructor(props) {
        super(props)
        this.initialState = {
            modalIsOpen: false,
            user: NewUser(),
            loading: false,
            loadingEmail: false,
            message: '',
            error: '',
            validation: {}
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))
    }

    open(user) {
        user = user ? JSON.parse(JSON.stringify(user)) : this.state.user

        this.setState({
            modalIsOpen: true,
            user
        })
    }

    close() {
        this.setState(this.initialState)
    }

    onChangeValue(key, event) {
        const user = JSON.parse(JSON.stringify(this.state.user))
        user[key] = event.target.value
        this.setState({user})
    }

    onChangeChecked(key, event) {
        const user = JSON.parse(JSON.stringify(this.state.user))
        user[key] = event.target.checked
        this.setState({user})
    }

    onChangeMessengers(messengers) {
        const user = JSON.parse(JSON.stringify(this.state.user))
        user.messengers = _.pluck(messengers, 'value')
        this.setState({user})
    }

    onChangeCustomers(customers) {
        const {users} = this.props
        const user = JSON.parse(JSON.stringify(this.state.user))
        user.customers = _.pluck(customers, 'value')

        if (user.type === 'Coördinator') {
            users.map((messenger) => {
                if (messenger.type === 'Koerier') {
                    const messengerHasCustomer = _.some(user.customers, (customer) => {
                        return messenger.customers.indexOf(customer) > -1
                    })

                    if (messengerHasCustomer) {
                        user.messengers = _.unique([...user.messengers, messenger.name]).sort()
                    }
                }
            })
        }

        this.setState({user})
    }

    onSubmit(event) {
        event.preventDefault()
        const {user} = this.state

        const formValid = this.validate()

        if (formValid) {
            this.setState({loading: true, error: ''})

            UserActions.update(user, (err) => {
                if (err) {
                    this.setState({error: err, loading: false})
                } else {
                    this.close()
                }
            })
        }
    }

    onClickSendEmail(event) {
        event.preventDefault()
        const {user} = this.state

        this.setState({loadingEmail: true, error: '', message: ''})

        UserActions.sendWelcomeMail(user.email, (err) => {
            if (err) {
                this.setState({error: err, loadingEmail: false})
            } else {
                this.setState({message: 'Email is verstuurd', loadingEmail: false})
            }
        })
    }

    validate() {
        const {user} = this.state
        const validation = {}
        let valid = true
        let focusId = ''

        if (!user.salaryTable) {
            focusId = 'salaryTable'
            validation.salaryTable = true
            valid = false
        }

        if (!user.email) {
            focusId = 'email'
            validation.email = true
            valid = false
        }

        if (!user.name) {
            focusId = 'name'
            validation.name = true
            valid = false
        }

        if (!valid) {
            document.getElementById(focusId).focus()
        }

        this.setState({validation})
        return valid
    }

    render() {
        const {user, error, message, loading, loadingEmail, modalIsOpen, validation} = this.state
        const {activeUser, users, customers, salaryTables} = this.props

        return (
            <Modal show={modalIsOpen} size="lg" onHide={this.close.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {user._id ? 'Bewerk gebruiker' : 'Nieuwe gebruiker'}
                    </Modal.Title>
                </Modal.Header>

                <Form onSubmit={this.onSubmit.bind(this)}>
                    <Modal.Body>
                        {activeUser.type === 'Admin' &&
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Account type</Form.Label>
                                        <Form.Control
                                            size='sm'
                                            as='select'
                                            value={user.type}
                                            onChange={this.onChangeValue.bind(this, 'type')}
                                        >
                                            <option value='Koerier'>Koerier</option>
                                            <option value='Coördinator'>Coördinator</option>
                                            <option value='Admin'>Admin</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col/>
                            </Row>
                        }

                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Personeelsnummer</Form.Label>
                                    <Form.Control
                                        size='sm'
                                        value={user.employeeNumber}
                                        onChange={this.onChangeValue.bind(this, 'employeeNumber')}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Naam</Form.Label>
                                    <Form.Control
                                        size='sm'
                                        id='name'
                                        value={user.name}
                                        onChange={this.onChangeValue.bind(this, 'name')}
                                        isInvalid={validation.name}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Telefoon</Form.Label>
                                    <Form.Control
                                        size='sm'
                                        value={user.phone}
                                        onChange={this.onChangeValue.bind(this, 'phone')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        size='sm'
                                        id='email'
                                        type='email'
                                        value={user.email}
                                        onChange={this.onChangeValue.bind(this, 'email')}
                                        isInvalid={validation.email}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Geboortedatum</Form.Label>
                                    <DatePicker
                                        size='sm'
                                        showTodayButton={false}
                                        value={user.dateOfBirth}
                                        onChange={this.onChangeValue.bind(this, 'dateOfBirth')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Datum in dienst</Form.Label>
                                    <DatePicker
                                        size='sm'
                                        value={user.dateOfEmployment}
                                        onChange={this.onChangeValue.bind(this, 'dateOfEmployment')}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Loonstaffel</Form.Label>
                                    <Form.Control
                                        size='sm'
                                        id='salaryTable'
                                        as='select'
                                        value={user.salaryTable}
                                        onChange={this.onChangeValue.bind(this, 'salaryTable')}
                                        isInvalid={validation.salaryTable}
                                    >
                                        <option value=''/>

                                        {salaryTables.map((salaryTable) => {
                                            return (
                                                <option key={salaryTable._id} value={salaryTable.name}>{salaryTable.name}</option>
                                            )
                                        })}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label/>
                                    <Form.Check
                                        label='Werkzaam als zzp`er'
                                        checked={user.zzp}
                                        onChange={this.onChangeChecked.bind(this, 'zzp')}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <br/>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Klanten</Form.Label>
                                    <Select
                                        isMulti
                                        placeholder='Selecteer...'
                                        options={customers.map((customer) => {
                                            return {value: customer.name, label: customer.name}
                                        })}
                                        value={(user.customers || []).map((customer) => {
                                            return {value: customer, label: customer}
                                        })}
                                        onChange={this.onChangeCustomers.bind(this)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        {user.type === 'Coördinator' &&
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Koeriers</Form.Label>
                                        <Select
                                            isMulti
                                            placeholder='Selecteer...'
                                            options={_.filter(users, (user) => user.type === 'Koerier').map((user) => {
                                                return {value: user.name, label: user.name}
                                            })}
                                            value={(user.messengers || []).map((user) => {
                                                return {value: user, label: user}
                                            })}
                                            onChange={this.onChangeMessengers.bind(this)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        }

                        {message &&
                            <Alert variant='success'>{message}</Alert>
                        }
                        {error &&
                            <Alert variant='danger'>{error}</Alert>
                        }
                    </Modal.Body>

                    <Modal.Footer>
                        <Button type="submit" disabled={loading}>
                            {loading ? <Spinner fadeIn='none'/> : 'Opslaan'}
                        </Button>
                        <Button variant='success' disabled={!user._id} onClick={this.onClickSendEmail.bind(this)}>
                            {loadingEmail ? <Spinner fadeIn='none'/> : 'Verstuur welkomstmail'}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        )
    }
}

export default Radium(UserModal)
