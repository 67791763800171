import moment from 'moment'

export default () => {
    return JSON.parse(JSON.stringify(
        {
            type: 'Koerier',
            employeeNumber: '',
            name: '',
            email: '',
            phone: '',
            dateOfBirth: moment().format('YYYY-MM-DD'),
            dateOfEmployment: moment().format('YYYY-MM-DD'),
            customers: [],
            messengers: [],
            salaryTable: '',
            zzp: false
        }
    ))
}
