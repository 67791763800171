import React, {Component} from 'react'
import Radium from 'radium'

import {Badge} from 'react-bootstrap'
import {Colors} from '../'


class SearchQuery extends Component {
    render() {
        const {onRemove, query} = this.props

        return (
            <h5 style={{marginRight: 10}}>
                <Badge variant="success">
                    <div style={{display: 'flex'}}>
                        <div>
                            {`${query.title} ${query.title && (query.operator === '!=' ? String.fromCharCode('8800') : query.operator)} ${query.text}`}
                        </div>
                        <div style={{marginLeft: 10, cursor: 'pointer', color: Colors.light}} onClick={onRemove}>
                            <i className='mdi mdi-close'/>
                        </div>
                    </div>
                </Badge>
            </h5>

        )
    }
}

export default Radium(SearchQuery)
