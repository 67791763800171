import React from 'react'
import Reflux from 'reflux'
import Radium from 'radium'
import moment from 'moment'
import _ from 'underscore'

import UserStore from '../stores/UserStore'
import UserActions from '../actions/UserActions'
import CustomerStore from '../stores/CustomerStore'
import SettingsStore from '../stores/SettingsStore'

import {Button, Form, Table} from 'react-bootstrap'
import {Colors, Popup, SearchBar} from '../components/UI'
import UserModal from '../components/users/UserModal'


class Users extends Reflux.Component {
    constructor(props) {
        super(props)
        this.state = {
            columns: [
                {title: 'Type', key: 'type', render: (user) => user.type},
                {title: 'Naam', key: 'name', render: (user) => user.name},
                {title: 'Geboortedatum', key: 'dateOfBirth', render: (user) => moment(user.dateOfBirth).format('DD-MM-YYYY')},
                {title: 'Datum in dienst', key: 'dateOfEmployment', render: (user) => moment(user.dateOfEmployment).format('DD-MM-YYYY')},
                {title: 'Email', key: 'email', render: (user) => user.email},
                {title: 'Staffel', key: 'salaryTable', render: (user) => user.salaryTable}
            ]
        }

        this.stores = [UserStore, CustomerStore, SettingsStore]
    }

    componentDidMount() {
        UserActions.get(true)
    }

    onClickNew() {
        this.userModal.open()
        UserActions.setSelected([])
    }

    onClickEdit(user) {
        this.userModal.open(user)
        UserActions.setSelected([])
    }

    onClickRemove() {
        const {selectedUsers} = this.state

        if (selectedUsers.length === 1) {
            this.popup.open('Gebruiker verwijderen', 'Weet je dit zeker?', () => {
                UserActions.remove(selectedUsers[0], (err) => {
                    if (!err) {
                        UserActions.setSelected([])
                        this.popup.close()
                    } else {
                        this.popup.setError(err)
                    }
                })
            })
        }
    }

    onClickSelectAll() {
        const {users, selectedUsers} = this.state

        if (users.length === selectedUsers.length) {
            UserActions.setSelected([])
        } else {
            UserActions.setSelected(_.pluck(users, '_id'))
        }
    }

    onClickSelect(id) {
        const selectedUsers = JSON.parse(JSON.stringify(this.state.selectedUsers))
        const index = selectedUsers.indexOf(id)

        if (index === -1) {
            selectedUsers.push(id)
        } else {
            selectedUsers.splice(index, 1)
        }

        UserActions.setSelected(selectedUsers)
    }

    render() {
        const {users, selectedUsers, unfilteredUsers, userQueries, usersSortKey, usersSortReverse, columns, unfilteredCustomers, salaryTables} = this.state
        const {user} = this.props

        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%'}}>
                <div style={{display: 'flex', width: '100%', padding: 20}}>
                    <SearchBar
                        columns={columns}
                        queries={userQueries || []}
                        onChange={UserActions.changeQueries}
                    />

                    <div style={{display: 'flex', height: 38}}>
                        <Button style={{marginLeft: 10}} onClick={this.onClickNew.bind(this)}>Nieuwe Gebruiker</Button>

                        {selectedUsers.length === 1 &&
                            <Button
                                style={{marginLeft: 10}}
                                variant='danger'
                                onClick={this.onClickRemove.bind(this)}
                            >
                                Verwijder Gebruiker
                            </Button>
                        }
                    </div>
                </div>

                <div style={{background: Colors.light, width: '100%', height: '100%', overflowY: 'scroll'}}>
                    <Table responsive >
                        <thead>
                            <tr>
                                <th>
                                    <Form.Check
                                        checked={users.length && selectedUsers.length === users.length}
                                        onChange={this.onClickSelectAll.bind(this)}
                                    />
                                </th>
                                {columns.map((column) => {
                                    return (
                                        <th
                                            key={column.key}
                                            style={{cursor: 'pointer', userSelect: 'none'}}
                                            onClick={() => UserActions.changeSortKey(column.key)}
                                        >
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <div>{column.title}</div>
                                                {column.key === usersSortKey && usersSortReverse &&
                                                    <i className='mdi mdi-sort-descending'/>
                                                }
                                                {column.key === usersSortKey && !usersSortReverse &&
                                                    <i className='mdi mdi-sort-ascending'/>
                                                }
                                            </div>
                                        </th>
                                    )
                                })}
                                <th/>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user) => {
                                return (
                                    <tr key={user._id}>

                                        <td>
                                            <Form.Check
                                                checked={selectedUsers.indexOf(user._id) > -1}
                                                onChange={this.onClickSelect.bind(this, user._id)}
                                            />
                                        </td>
                                        {columns.map((column) => {
                                            return <td key={column.key}>{column.render(user)}</td>
                                        })}
                                        <td>
                                            <Button size='sm' onClick={this.onClickEdit.bind(this, user)}>
                                                <i className='mdi mdi-pencil'/>
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>

                <UserModal
                    activeUser={user}
                    users={unfilteredUsers}
                    customers={unfilteredCustomers}
                    salaryTables={salaryTables}
                    ref={(ref) => this.userModal = ref}
                />

                <Popup ref={(ref) => this.popup = ref}/>
            </div>
        )
    }
}

export default Radium(Users)
