import Reflux from 'reflux'

export default Reflux.createActions([
    'getPriceTables',
    'updatePriceTable',
    'removePriceTable',
    'selectPriceTable',

    'getSalaryTables',
    'updateSalaryTable',
    'removeSalaryTable',
    'selectSalaryTable',

    'getMessageSets',
    'updateMessageSet',
    'removeMessageSet',
    'selectMessageSet'
])
