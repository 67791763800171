import React from 'react'
import Reflux from 'reflux'
import Radium from 'radium'
import _ from 'underscore'

import CustomerStore from '../stores/CustomerStore'
import CustomerActions from '../actions/CustomerActions'
import SettingsStore from '../stores/SettingsStore'

import {Button, Form, Table} from 'react-bootstrap'
import {Colors, Popup, SearchBar} from '../components/UI'
import CustomerModal from '../components/customers/CustomerModal'

class Customers extends Reflux.Component {
    constructor(props) {
        super(props)
        this.state = {
            columns: [
                {title: 'Naam', visible: true, key: 'name', render: (customer) => customer.name},
                {title: 'Straat', key: 'address.street', render: (customer) => customer.address.street},
                {title: 'Nr', key: 'address.nr', render: (customer) => customer.address.nr},
                {title: 'Toevoeging', key: 'address.addition', render: (customer) => customer.address.addition},
                {title: 'Postcode', key: 'address.postalCode', render: (customer) => customer.address.postalCode},
                {title: 'Plaats', key: 'address.city', render: (customer) => customer.address.city},
                {title: 'Email', key: 'email', render: (customer) => customer.email},
                {title: 'Tarieftabel', key: 'priceTable', render: (customer) => customer.priceTable},
                {title: 'Berichtenset', key: 'messageSet', render: (customer) => customer.messageSet}
            ]
        }

        this.stores = [CustomerStore, SettingsStore]
    }

    componentDidMount() {
        CustomerActions.get(true)
    }

    onClickNew() {
        this.customerModal.open()
        CustomerActions.setSelected([])
    }

    onClickEdit(customer) {
        this.customerModal.open(customer)
        CustomerActions.setSelected([])
    }

    onClickRemove() {
        const {selectedCustomers} = this.state

        if (selectedCustomers.length === 1) {
            this.popup.open('Klant verwijderen', 'Weet je dit zeker?', () => {
                CustomerActions.remove(selectedCustomers[0], (err) => {
                    if (!err) {
                        CustomerActions.setSelected([])
                        this.popup.close()
                    } else {
                        this.popup.setError(err)
                    }
                })
            })
        }
    }

    onClickSelectAll() {
        const {customers, selectedCustomers} = this.state

        if (customers.length === selectedCustomers.length) {
            CustomerActions.setSelected([])
        } else {
            CustomerActions.setSelected(_.pluck(customers, '_id'))
        }
    }

    onClickSelect(id) {
        const selectedCustomers = JSON.parse(JSON.stringify(this.state.selectedCustomers))
        const index = selectedCustomers.indexOf(id)

        if (index === -1) {
            selectedCustomers.push(id)
        } else {
            selectedCustomers.splice(index, 1)
        }

        CustomerActions.setSelected(selectedCustomers)
    }

    render() {
        const {customers, selectedCustomers, customerQueries, customersSortKey, customersSortReverse, columns, priceTables, messageSets} = this.state
        const {user} = this.props

        if (!user || user.type !== 'Admin') {
            return <div/>
        }

        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%'}}>
                <div style={{display: 'flex', width: '100%', padding: 20}}>
                    <SearchBar
                        columns={columns}
                        queries={customerQueries || []}
                        onChange={CustomerActions.changeQueries}
                    />

                    <div style={{display: 'flex', height: 38}}>
                        <Button style={{marginLeft: 10}} onClick={this.onClickNew.bind(this)}>Nieuwe Klant</Button>

                        {selectedCustomers.length === 1 &&
                            <Button
                                style={{marginLeft: 10}}
                                variant='danger'
                                onClick={this.onClickRemove.bind(this)}
                            >
                                Verwijder Klant
                            </Button>
                        }
                    </div>
                </div>

                <div style={{background: Colors.light, width: '100%', height: '100%', overflowY: 'scroll'}}>
                    <Table responsive >
                        <thead>
                            <tr>
                                <th>
                                    <Form.Check
                                        checked={customers.length && selectedCustomers.length === customers.length}
                                        onChange={this.onClickSelectAll.bind(this)}
                                    />
                                </th>
                                {columns.map((column) => {
                                    return (
                                        <th
                                            key={column.key}
                                            style={{cursor: 'pointer', userSelect: 'none'}}
                                            onClick={() => CustomerActions.changeSortKey(column.key)}
                                        >
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <div>{column.title}</div>
                                                {column.key === customersSortKey && customersSortReverse &&
                                                    <i className='mdi mdi-sort-descending'/>
                                                }
                                                {column.key === customersSortKey && !customersSortReverse &&
                                                    <i className='mdi mdi-sort-ascending'/>
                                                }
                                            </div>
                                        </th>
                                    )
                                })}
                                <th/>
                            </tr>
                        </thead>
                        <tbody>
                            {customers.map((customer) => {
                                return (
                                    <tr key={customer._id}>

                                        <td>
                                            <Form.Check
                                                checked={selectedCustomers.indexOf(customer._id) > -1}
                                                onChange={this.onClickSelect.bind(this, customer._id)}
                                            />
                                        </td>
                                        {columns.map((column) => {
                                            return <td key={column.key}>{column.render(customer)}</td>
                                        })}
                                        <td>
                                            <Button size='sm' onClick={this.onClickEdit.bind(this, customer)}>
                                                <i className='mdi mdi-pencil'/>
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>

                <CustomerModal ref={(ref) => this.customerModal = ref} priceTables={priceTables} messageSets={messageSets}/>
                <Popup ref={(ref) => this.popup = ref}/>

            </div>
        )
    }
}

export default Radium(Customers)
