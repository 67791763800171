import React from 'react'
import Radium from 'radium'

import CustomerActions from '../../actions/CustomerActions.js'

import {Alert, Button, Modal, Form, Row, Col} from 'react-bootstrap'
import {Address} from '../forms'
import Spinner from 'react-spinkit'

import NewCustomer from './NewCustomer.js'

class CustomerModal extends React.Component {
    constructor(props) {
        super(props)
        this.initialState = {
            modalIsOpen: false,
            customer: NewCustomer(),
            loading: false,
            error: '',
            validation: {}
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))
    }

    open(customer) {
        customer = customer ? JSON.parse(JSON.stringify(customer)) : this.state.customer

        this.setState({
            modalIsOpen: true,
            customer
        })
    }

    close() {
        this.setState(this.initialState)
    }

    onChangeValue(key, event) {
        const {customer} = this.state
        customer[key] = event.target.value
        this.setState({customer})
    }

    onChangeChecked(key, event) {
        const {customer} = this.state
        customer[key] = event.target.checked

        if (key === 'invoiceHours') {
            customer.distanceFee = false
        }
        this.setState({customer})
    }

    onAddressChange(address) {
        const {customer} = this.state
        customer.name = address.name
        customer.address = address
        this.setState({customer})
    }

    onSubmit(event) {
        event.preventDefault()
        const {customer} = this.state

        const formValid = this.validate()
        const addressValid = this.addressForm.validate()

        if (addressValid && formValid) {
            this.setState({loading: true, error: ''})


            CustomerActions.update(customer, (err) => {
                if (err) {
                    this.setState({error: err, loading: false})
                } else {
                    this.close()
                }
            })
        }
    }

    validate() {
        const {customer} = this.state
        const validation = {}
        let valid = true
        let focusId = ''

        if (!customer.messageSet) {
            focusId = 'messageSet'
            validation.messageSet = true
            valid = false
        }

        if (!customer.priceTable) {
            focusId = 'priceTable'
            validation.priceTable = true
            valid = false
        }

        if (!valid) {
            document.getElementById(focusId).focus()
        }

        this.setState({validation})
        return valid
    }

    render() {
        const {customer, error, loading, modalIsOpen, validation} = this.state
        const {priceTables, messageSets} = this.props

        return (
            <Modal show={modalIsOpen} size="lg" onHide={this.close.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {customer._id ? 'Bewerk klant' : 'Nieuwe klant'}
                    </Modal.Title>
                </Modal.Header>

                <Form onSubmit={this.onSubmit.bind(this)}>
                    <Modal.Body>
                        <Address
                            address={customer.address}
                            onChange={this.onAddressChange.bind(this)}
                            ref={(ref) => this.addressForm = ref}
                        />
                        <br/>

                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Telefoon</Form.Label>
                                    <Form.Control
                                        size='sm'
                                        value={customer.phone}
                                        onChange={this.onChangeValue.bind(this, 'phone')}
                                    />
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        size='sm'
                                        type='email'
                                        value={customer.email}
                                        onChange={this.onChangeValue.bind(this, 'email')}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Tarieftabel</Form.Label>
                                    <Form.Control
                                        id='priceTable'
                                        size='sm'
                                        as='select'
                                        value={customer.priceTable}
                                        onChange={this.onChangeValue.bind(this, 'priceTable')}
                                        isInvalid={validation.priceTable}
                                    >
                                        <option value=''/>
                                        {priceTables.map((priceTable) => {
                                            return (
                                                <option key={priceTable.name} value={priceTable.name}>{priceTable.name}</option>
                                            )
                                        })}
                                    </Form.Control>
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group>
                                    <Form.Label>Berichtenset</Form.Label>
                                    <Form.Control
                                        id='messageSet'
                                        size='sm'
                                        as='select'
                                        value={customer.messageSet}
                                        onChange={this.onChangeValue.bind(this, 'messageSet')}
                                        isInvalid={validation.messageSet}
                                    >
                                        <option value=''/>
                                        {messageSets.map((messageSet) => {
                                            return (
                                                <option key={messageSet.name} value={messageSet.name}>{messageSet.name}</option>
                                            )
                                        })}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <br/>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Check
                                        size='sm'
                                        label='Extra apotheek'
                                        checked={customer.extraPharmacy}
                                        onChange={this.onChangeChecked.bind(this, 'extraPharmacy')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Check
                                        size='sm'
                                        label='Voorrijkosten'
                                        checked={customer.callOutFee}
                                        onChange={this.onChangeChecked.bind(this, 'callOutFee')}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Check
                                        size='sm'
                                        label='Factuur op uurbasis'
                                        checked={customer.invoiceHours}
                                        onChange={this.onChangeChecked.bind(this, 'invoiceHours')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                {customer.invoiceHours &&
                                    <Form.Group>
                                        <Form.Check
                                            size='sm'
                                            label='Vergoeding per km'
                                            checked={customer.distanceFee}
                                            onChange={this.onChangeChecked.bind(this, 'distanceFee')}
                                        />
                                    </Form.Group>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Check
                                        size='sm'
                                        label='Minimum per dag'
                                        checked={customer.minimumPerDay}
                                        onChange={this.onChangeChecked.bind(this, 'minimumPerDay')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col/>
                        </Row>

                        {error &&
                            <Alert variant='danger'>{error}</Alert>
                        }
                    </Modal.Body>

                    <Modal.Footer>
                        <Button type="submit" disabled={loading}>
                            {loading ? <Spinner fadeIn='none'/> : 'Opslaan'}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        )
    }
}

export default Radium(CustomerModal)
