import Reflux from 'reflux'

export default Reflux.createActions([
    'login',
    'logout',
    'logoutAll',
    'loggedIn',
    'update',
    'sendResetPasswordMail',
    'resetPassword'
])
