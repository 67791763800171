import React from 'react'
import Radium from 'radium'

import SettingsActions from '../../actions/SettingsActions.js'

import {Alert, Button, Card, Form} from 'react-bootstrap'
import {Price} from '../forms'
import {Popup} from '../UI'


class MessageSet extends React.Component {
    constructor(props) {
        super(props)

        this.state ={
            messageSet: JSON.parse(JSON.stringify((props.messageSet))),
            edit: false,
            message: '',
            error: ''
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.messageSet) !== JSON.stringify(prevProps.messageSet)) {
            this.setState(({messageSet: JSON.parse(JSON.stringify(this.props.messageSet)), message: '', error: ''}))
        }
    }

    onChangeValue(key, event) {
        const {messageSet} = this.state
        messageSet[key] = event.target.value
        this.setState({messageSet})
    }

    onChangeRate(event) {
        const {messageSet} = this.state
        messageSet.income = parseFloat(event.target.value)
        this.setState({messageSet})
    }

    onClickSave() {
        const {messageSet} = this.state

        this.setState({message: '', error: ''})

        SettingsActions.updateMessageSet(messageSet, (err) => {
            if (err) {
                this.setState({error: err})
            } else {
                this.setState({edit: false, message: 'Wijzigingen opgeslagen'})
            }
        })
    }

    onClickRemove() {
        const {messageSet} = this.state

        this.popup.open('Verwijder Berichtenset', '', () => {
            SettingsActions.removeMessageSet(messageSet._id, (err) => {
                if (err) {
                    this.popup.setError(err)
                }
            })
        })
    }

    render() {
        const {messageSet, edit, message, error} = this.state

        if (!messageSet) {
            return null
        }

        return (
            <Card style={{marginTop: 20, minWidth: 500}}>
                <Card.Header>
                    <Card.Title>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            {edit ?
                                <Form.Control style={{width: 300}} value={messageSet.name} onChange={this.onChangeValue.bind(this, 'name')}/> :
                                <h5>{messageSet.name}</h5>
                            }
                        </div>
                    </Card.Title>
                </Card.Header>

                <Card.Body>
                    <Form.Group>
                        <Form.Label>Streef inkomen</Form.Label>
                        <Price
                            value={messageSet.income || 0}
                            onChange={this.onChangeRate.bind(this)}
                            plaintext={!edit}
                            readOnly={!edit}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Bericht 1</Form.Label>
                        <Form.Control
                            as='textarea'
                            value={messageSet.message1}
                            onChange={this.onChangeValue.bind(this, 'message1')}
                            plaintext={!edit}
                            readOnly={!edit}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Bericht 2</Form.Label>
                        <Form.Control
                            as='textarea'
                            value={messageSet.message2}
                            onChange={this.onChangeValue.bind(this, 'message2')}
                            plaintext={!edit}
                            readOnly={!edit}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Bericht 3</Form.Label>
                        <Form.Control
                            as='textarea'
                            value={messageSet.message3}
                            onChange={this.onChangeValue.bind(this, 'message3')}
                            plaintext={!edit}
                            readOnly={!edit}
                        />
                    </Form.Group>

                    {message &&
                        <Alert variant='success' dismissible onClose={() => this.setState({message: ''})}>{message}</Alert>
                    }
                    {error &&
                        <Alert variant='danger' dismissible onClose={() => this.setState({error: ''})}>{error}</Alert>
                    }
                </Card.Body>

                <Card.Footer>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        {edit ?
                            <Button onClick={this.onClickSave.bind(this)}>Opslaan</Button> :
                            <Button onClick={() => this.setState({edit: true, message: ''})}>Wijzigen</Button>
                        }
                        <Button variant='danger' onClick={this.onClickRemove.bind(this)} >
                            Verwijder
                        </Button>
                    </div>
                </Card.Footer>

                <Popup ref={(ref) => this.popup = ref}/>
            </Card>
        )
    }
}

export default Radium(MessageSet)
